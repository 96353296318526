#WelcomeScreen{
    height: 100%;
    background: transparent;
    /* width: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
    /* box-sizing: border-box;
    -webkit-box-sizing: border-box; */
}

#WelcomeScreen .navbar{
    display:none
}

#WelcomeScreen::-webkit-scrollbar {
display: none;
  }
#WelcomeScreen .row{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border-style: solid; */
    /* border-color: yellow; */
    /* padding-right: 20px;
    padding-left: 20px; */
    height: 100%;
}

.WelcomeHeader{
    width:100%;
    height:60px;
    border: 1px solid #E5E5E5;
  }


  @media screen and (max-width:8090px) {
    #WelcomeScreen{
        /* height: 100vh; */
        width: auto;
        /* border-style: solid; */
        height: 100vh;
        overflow: scroll;
        /* padding-bottom: 16rem; */
        scroll-behavior: smooth;
        
                
    }
    #WelcomeScreen .row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* border-style: solid; */
        /* border-color: yellow; */
        /* padding-right: 20px;
        padding-left: 20px; */
        flex-flow: column;
        padding-bottom: 40rem;
    }
    .PinkDiv{
        width: 100%;
        background: #FDF4EC;
        height:100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        /* padding-right: 30px; */

    }
    .pinkImageDiv{
        height: 55px;
        width: 60px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .pinkImage{
        height: 30px;
    }
    .pinkTextDiv{
        width: 280px;

        /* border:1px solid black; */
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 20px ;
        margin-left: 20px;
    }

    #WelcomeScreen h2{
        font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
/* identical to box height */

/* text-align: center; */

color: #333333;
/* border:1px solid black; */
height:40px
    }

    #WelcomeScreen p{
        font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;

color: #333333;
height: 20px;
/* border:1px solid red; */
margin-left: 0;
margin-top: 0px;
/* padding-left: 5px; */
    }
    .grayDivWelcome{
        width: 95%;
        margin-left: 13px;
        margin-right: 10px;
        margin-top: 15px;
        background: #FAFAFA;
border-radius: 5px;
padding: 15px;
display: flex;
flex-direction: column;
/* justify-content: flex-start; */
align-items: flex-start;
height: auto;
    }

    #WelcomeScreen h4{
        font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
/* identical to box height */

text-align: center;
/* border:1px solid blue; */

color: #333333;
height:20px
    }
    .cardDiv{
        width: 100%;
        background: #FFFFFF;
border: 1px solid #D4D4D4;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
border-radius: 4px;
height: auto;
margin-top: 20px;
padding-top:20px;
padding-left:20px;
display: flex;
padding-bottom: 14px;
cursor: pointer;
flex-direction: column;
    }
.listStyleCardDiv{
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    height: 22px;
    /* justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: -90px; */
}
    .circle{
        height: 20px;
        width:20px;
        border-radius: 50%;
        border: 2px solid #3A86FF;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 5px;
    }

    .point{
     height: 10px ;
     width: 10px ;
     border-radius: 50%;
     background:#3A86FF ;
    }

    .welcomeUnorderList{
        /* border:1px solid black; */
        width: fit-content;
        /* margin-left: 10px; */
        padding-left: 5px;
        padding-top: 0;
        font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 16px;
/* line-height: 18px; */

color: #333333;
list-style:none;
height: fit-content;

    }

    .WelcomeList{
        height: fit-content ;
        /* border:1px solid red; */
        margin-top: 10px ;
        width: 100%;
        font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */


color: #333333;
display: flex;
flex-direction: row;



    }
    .greenCheck{
        margin-right: 10px;
        height: 10px;
        /* border: 1px solid black; */
        margin-top: 5.5px;
    }
    .cardDiv1{
        width: 100%;
        background: rgba(217, 217, 217, 0.72);
border: 1px solid #D4D4D4;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
border-radius: 4px;
height: auto;
margin-top: 20px;
padding-top:20px;
padding-left:20px;
display: flex;
padding-bottom: 0px;
cursor: pointer;
flex-direction: column;
padding-bottom: 20px;
    }
.listText{
    line-height: 19px;
    padding-right: 10px;
    display: inline-flex;
    /* border: 1px solid black; */
    height: fit-content;
}
    .circle1{
        height: 20px;
        width:20px;
        border-radius: 50%;
        border: 2px solid gray;
        align-items: center;
        justify-content: center;
        display: flex;
        /* margin-top: 5px; */
    }

    .point1{
     height: 10px ;
     width: 10px ;
     border-radius: 50%;
     background:#3A86FF ;
     display: none;
    }

    .welcomeUnorderList1{
        /* border:1px solid black; */
        width: 100%;
        /* margin-left: 10px; */
        padding-left: 5px;
        padding-top: 0;
        font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;

color: gray;
list-style:none;

    }

    .WelcomeList1{
        height: fit-content ;
        /* border:1px solid red; */
        margin-top: 10px ;
        /* width: 100%; */
        font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */
display: flex;
flex-direction: row;


color: gray;


    }
    .greenCheck1{
        margin-right: 10px;
        height: 10px;
        margin-top: 5.5px;
    }

    .buttonDiv{
        display: flex;
        flex: 1;
        /* border: 1px solid black; */
        align-items: flex-end;
        /* padding-bottom: 20px; */
        /* margin-top: -100px; */
        /* height: 100px; */

    }
    .buttonDiv2{
        display: flex;
        /* flex: 1; */
        /* border: 1px solid black; */
        /* align-items: flex-end; */
        /* padding-bottom: 20px; */
        /* margin-top: -100px; */
        /* height: 100px; */
        /* margin-top: 20px; */
        padding-bottom: 20px;
        height: 100px;
        justify-content: center;

    }
  }


  @media screen and (min-width:340px ) and (max-width:480px) {
    #WelcomeScreen p{
        font-size: 16px;
        width: 100% !important;
        /* border: black  1px solid; */
        margin-left: 0.4% !important;
        /* position: relative; */
        /* margin-top: 1px; */
        line-height: 19px;
        height: fit-content;
        margin-top: -5px;
   }
   #WelcomeScreen h2{
       margin-top: 10px;
   }
  
  }
  @media screen and (min-width:400px ) and (max-width:480px) {
    
  
  }
  @media screen and (min-width:340px ) and (max-width:580px) {
    
.WelcomeList{
    font-size: 16px;
    text-align:start;
}
.welcomeUnorderList{
    font-size: 19px;
}
.welcomeUnorderList1{
    font-size: 19px;
}
.WelcomeList1{
    font-size: 16px;
}

#WelcomeScreen h2{
    font-size: 19px;
    width: max-content;
    height: fit-content;
}
#WelcomeScreen p{
     font-size: 16px;
     width: max-content;
     /* border: black  1px solid; */
     margin-left: 38%;
     /* position: relative; */
     /* margin-top: 1px; */
}


  
  }