
/* <!-Hero Section-> */

#Hero{
    /* min-height: 800px; */
    /* height: 100vh; */
    /* max-height: 1290px; */
    /* height: 1%; */
    height: 100%;
    
    background: transparent;     background-size:cover;
    background-image:url('../images/NEW\ BG.png');
    background-position: 0 0, 0 0;
background-repeat: no-repeat, no-repeat;
    display:flex;
    overflow:hidden;
    /* align-items: center;  */
    /* flex-flow: column; */
    /* justify-content: center; */
    /* justify-content:center;
    /* flex-direction: column; */
    /* z-index: 1300; */
    /* border-color: red;
    border-style: solid; */
}


.hero-MobileImage{
    display:none 
}

#Hero .col{
    /* border-color: brown;
    border-style: solid; */
    /* justify-content: center; */
    align-items: center;
    display: flex;
    /* border-color: crimson;
    border-style: solid; */
    /* width: 100%; */
    /* padding: 0px;
    margin: 0px; */
    padding:0;
    
}








section,
.section {
    position: relative;
}

 .weMake-Big{
    font-size: 40px;
    font-weight: bold;
    line-height:46px;
    color:#141414;
    /* padding:1.063rem  0; */
    
    font-family: Asap;
    width: 530px;
    /* border-style: solid;
    border-color: orange; */
    height: 90px;
}
 .weMake-small{
    font-size: 16px;
    color: #1E1E1E;
    font-weight: normal;
    font-style: normal;
font-family: Asap;
line-height: 25px;
    
    margin-top: 25px;
    /* border-style: solid;
    border-color: crimson; */
    height: auto;
    width: 450px;
    ;

}

.hero-text{
    /* border-color: aqua;
    border-style: solid; */
    width: 560px;
    margin-top: 102px;
    /* margin-left: -20px; */
    /* height: 470px; */
    display: flex;
     /* align-items: center; */
    justify-content: center; 
    flex-direction: column;
    height: 350px;
    align-self: center;
    margin-left: 10px;
    
}

 .tryNow-btn{
    background-color: #3a86ff;
    color: #fff;
    border: 1px solid var(--primary);
    /* transition: all .3s ease-out; */
    width: 190px;
    height: 46px;
    font-size: 16px;
    /* border-radius: 2px; */
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 29px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
 }

 .hero-Image{
    /* min-width: 590px;
    /* height: 884px; */
    /* height: auto;
    width: auto; */
     display: block;
     /* white-space: nowrap; */
    /* margin-top: 60px; */
    /* position: relative; */
    /* border-color: #3a86ff;
    border-style: solid; */
    /* flex-basis: content; */
    /* min-width: 315px; */
    margin-left: 15px;
    height: 395px;
    margin-top: 90px;
    
 }



/* <!-WhyCeplr section-> */

#whyCeplr{
    height: auto;
    /* min-height: 100px; */
    background:#FFFF no-repeat scroll left center;
    background-size: cover;
    display:flex;
    /* align-items: center; */
    justify-content:center;
    overflow:hidden;
    flex-direction: row;
    /* border-color: lawngreen;
    border-style: solid; */
    padding-bottom: 7rem;
}



#whyCeplr .col{
    /* border-color: brown;
    border-style: solid; */
    /* justify-content: center; */
    align-items: center;
    /* display: flex; */
    /* border-color: crimson;
    border-style: solid; */
    /* width: fit-content; */
    padding: 0;
    /* display: flex; */
    
}

#whyCeplr .row{
    /* border-style: solid;
    border-color: blue; */
    /* width: fit-content; */
    margin:0px;
    padding:0px;
    /* width: 100%; */
}


#whyCeplr .container{
    /* border-style: solid; */
    /* width: auto */
}

#whyCeplr .whyCeplrText-Container{
    width: fit-content;
    margin-top:172px;
    /* border-style: solid;
    border-color: black; */
    /* position: absolute; */
margin-left:100px;
;
}

.whyCeplrBigText{
    width: fit-content;
    font-size: 39px;
    font-family: Asap;
    font-weight: bold;
    line-height: 42px;
    
    /* margin-top:10px; */
    /* border-style: solid; */

}


.whyCeplr-linksDiv{
display:flex;
height: auto ;
width: 390px;
background:transparent;
/* border-style: solid; */
justify-content: space-between;
margin-top: 30px;
margin-left: 100px;

}
.whyCeplr-linksDiv1{
display:flex;
height: auto ;
width: 420px;
background:transparent;
/* border-style: solid; */
justify-content: space-between;
margin-top: 30px;
margin-left: 100px;

}
.whyLink1{
    font-family: Asap;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    
    color: #9E9E9E;
    
}
.whyLink2{
    font-family: Asap;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    
    color: #9E9E9E;
}
.thumbs{
height: 20px;
margin-right:10px ;
}
.whyCeplr-smallText1{
    /* max-height: 100px;
    max-width: 560px; */
    /* margin-left: 117px; */
    color: #1E1E1E;
    font-size: 16px;
    font-family: Asap;
    line-height: 25px;
    font-weight: 300;
    margin-top: 25px;
    /* border-style: solid; */
    width: 410px;
}

.whyCeplr-smallText2{
    /* max-height: 100px; */
    /* max-width: 560px; */
    /* margin-left: 117px; */
    color: #707070;
    font-size: 16px;
    font-family: Asap;
    line-height: 25px;
    font-weight: 300;
    margin-top: 30px;
}

.whyCeplr-Image{
    /* min-width: 600px; */
    margin-left: -30px;
    /* width: 100%; */
    height: 440px;
        margin-top: 100px;
    /* margin-bottom: 10rem; */
    position: relative;
}

.whyCeplrBG{
    position: absolute;
    height: 520px;
    align-self: flex-end;
    /* width: 650px; */
    margin-top:100px;
}



/* <--mobile section-> */


#Mobile{
    height: auto;
    /* min-height: 100px; */
    background:#FFF no-repeat scroll left center;
    background-size: cover;
    display:flex;
    align-items: center;
    /* justify-content:center; */
    overflow:hidden;
    /* border-color: yellow;
    border-style: solid; */
    padding-bottom: 7rem;
    box-sizing :border-box;
    -webkit-box-sizing: border-box;
}

.Mobile-image{
    
    
    /* width: 100%; */
    height: 600px;
    margin-top: 100px;
    position: relative;
    margin-bottom: 10rem;
    margin-left: 0;
    border-style: solid;
}

.howDoesitContainer{
    width:560px;
    margin-top: 70px;
    /* border-style: solid; */
    box-sizing :border-box;
    -webkit-box-sizing: border-box;
    
}
.howDoesBg{
    position: absolute;
    height: 630px;
    margin-top:170px;
    align-self: flex-start;
    box-sizing :border-box;
    -webkit-box-sizing: border-box;
}
.howDoes-Heading{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 23px;
/* identical to box height */


color: #222222;
/* position: absolute; */
/* margin-top: 40px; */
}

.howDoes-BigText{
    width: 500px;
    font-size: 39px;
    font-family: Asap;
    font-weight: bold;
    line-height: 47px;
    
    margin-top: 0px;
}

.text-container{
    height: 100%;
    /* width: 80%; */
    
        position: relative;
   
    margin-top: 55px;
    /* border-color: purple;
    border-style: solid; */
    padding-bottom: 50px;
    
    
  }

  .inner-text{
    /* height:140px; */
    width: 500px;
    
    margin-top: 25px;
    /* border-style: solid; */
    height: auto;
    padding-bottom: 20px;
    
    
  }
  .inner-text1{
   
    width: 560px;
    
    margin-top: 15px;
    /* border-style: solid; */
    height: auto;
    padding-bottom: 20px;
    box-shadow: 0px 6px 14px rgba(113, 113, 113, 0.25);
    
    
  }


  .how-doesImage{
    width: 100px;
    height: 90px;
    border-color: thistle;
    border-style: solid;
  }
  
  .big-text{
    font-size: 18px;
    color: #202222;    ;
    font-weight: 700;
    height:auto;
    width: 390px;
    display: grid;
    grid-template-columns: 6% auto ;
    ;
    grid-column-gap: 24px;
    
    /* margin-top:-80px; */
    /* margin-left: 1px; */
  }
  .round-container{
    /* background-color:  #E63946; */
    /* border-radius: 55%; */
    height:fit-content;
    width: fit-content;
    margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: inset  0px 0px 0px 4px pink; */
  align-items: center;
  /* border-style: solid; */
  


    
  }
  .round-text{
    color: #FFFFFF;
    font-size: 14px;
    font-family: Asap ;
    /* align-self: center; */
    margin-top: 8px;

  }
  .small-text{
    /* height: 120px; */
    width:520px;
    font-size: 16px;
    color:#1f1f1f;;
    margin-left: 45px;
    font-weight: 300;
    font-family: Asap ;
    line-height: 24px;
    
    margin-top: 1px;
    
    
    
  }

  .inner-textAgain{
    /* height:140px; */
    width: 500px;
    
    margin-top: 45px;
    /* border-style: solid;
    border-color: lightblue; */
    
  }
.howDoesCircleClass{
    position: absolute;
height: 80px;
}
.howDoesActualIconClass{
    position: relative;
margin-top: -8px;
height: 22px;
/* border-style: solid;
border-color: white; */
}
  @media screen and (min-width:1520px) and (max-width:1800px)  {
    .howDoesBg{
        height: 750px;
    }
    .weMake-Big{
        /* font-size: 42px; */
        font-size:48.9px;
        width: 620px;
        height: auto;
        /* border-style: solid;
        border-color: purple; */
        line-height: 52px;
        /* margin-top: 20px; */
    }
    .weMake-small{
        font-size:20.1px;
        width: 540px;
        height: auto;
        /* border-style: solid; */
        line-height: 30px;
        margin-top: 30px;

    }
    .hero-text{
        /* border-style: solid;
        border-color: red; */
        height: 400px;
        width: 660px;
    }
    .tryNow-btn{
        margin-top: 35px;
        /* margin-bottom: 60px; */
        /* height: 46px; */
    }
    
    .whyCeplrBG{
        height: 690px;
        /* margin-top: -40px; */
    }
    .whyCeplr-Image{
        /* margin-bottom: 12rem; */
        margin-top: 50px;
        margin-left: -30px;
        height: 590px;
    }
    #Mobile{
        /* height: 980px; */
        /* border-color: brown;
        border-style: solid; */
        padding-bottom: 9rem;
    }
    #whyCeplr{
        /* height: 660px; */
        /* border-color: #3a86ff;
        border-style: solid; */
        padding-bottom: 12rem;
    }
    .Mobile-image{
        height: 680px;
    }
    #Hero{
        /* min-height: 890px;
        height:100%;
        align-items: center; */
        /* height: 678px; */
        
    }
    .hero-Image{
        height: 500px;
        /* min-width:560px ; */
        /* width: 970px; */
        /* border-color: purple;
        border-style: solid; */
        margin-left: 15px;
    }
    .whyCeplrBigText{
        font-size: 48px;
        margin-top: 20px;
        /* border-style: solid; */
        width: auto;
        line-height: 50px;
    }
  .headingWhy-Ceplr{
     font-size: 26px;
  }
  .whyCeplr-smallText1{
      font-size: 20.1px;
      width: 520px;
      /* border-color: purple; */
      /* border-style: solid; */
      line-height: 30px;
      margin-top: 30px;
  }
  .whyCeplr-smallText2{
    font-size: 20.1px;
    width: 650px;
    border-color: purple;
    border-style: solid;
    line-height: 30px;
  }
  .howDoes-Heading{
      font-size: 26px;
  }
  .howDoes-BigText{
      font-size: 47px;
      margin-top: 20px;
      width: fit-content;
      line-height: 56px;
  }
  .big-text{
      font-size: 21.2px;
      /* border-style: solid; */
    width: 450px
    ;
    height: 35px;
    gap: 30px;

  }
  .round-container{
      margin-top: 22px;
      
  }
  .small-text{
      font-size: 19px;
      /* border-style: solid; */
      width: 590px;
      line-height: 30px;
      margin-top: 5px;
      margin-left: 60px;
  }
  .howDoesCircleClass{
      height: 100px;
  }
  .howDoesActualIconClass{
      height: 29px;
      margin-top: -12px;
  }
  .inner-text1{
      width: 640px;
  }
  #whyCeplr .whyCeplrText-Container{
    width: 520px;
    margin-top:132px;
    /* border-style: solid;
    border-color: black; */
    /* position: absolute; */
margin-left: 90px;
}

.whyCeplr-linksDiv{
    margin-left: 90px;
    width: 500px;
    margin-top: 50px;

}
.whyCeplr-linksDiv1{
    margin-left: 90px;
/* border-style: solid; */
width: 535px;
margin-top: 50px;
}
 
.whyLink1{
    font-size: 21.2px;
    line-height: 21px;
    
    
}
.whyLink2{
    font-size: 21.2px
    ;
}
.thumbs{
height: 20px;
margin-right:10px ;
}

  }

  @media screen and (min-width:1400px) and (max-width:1520px)  {
    .hero-Image{
        height: 450px;
        margin-left: 15px;
        /* border-color: #3a86ff;
        border-style: solid; */
        margin-top: 50px;
 
    }
    .hero-text{
        /* margin-top:254px; */
        /* border-color: #3a86ff;
        border-style: solid; */
        height: 400px;
        margin-top: 30px;
        width: 600px;
    }
    
    .weMake-small{
        /* border-style: solid;
        border-color: darkblue; */
        width: 460px;
    }
    .weMake-Big{
        font-size: 43px;
        width: 580px;
    }
    .whyCeplrBigText{
        font-size: 40px;
        margin-top: 20px;
        /* border-style: solid; */
        width: 409px;
        line-height: 40px;
    }
    .whyCeplr-smallText1{
/* font-size: 18.2px;
border-style: solid; */
    }
    
    .whyCeplr-linksDiv{
        margin-left: 10px;
        margin-top: 50px;
    }
    .whyCeplr-linksDiv1{
        margin-left: 10px;
        margin-top: 50px;

    }
    
      
  }

  @media screen and (min-width:1800px) {
      #Hero{
          height: 890px;
      }
      
  }

  @media only screen and (min-width : 1230px) and (max-width:1800px) {
    .container { max-width: 1500px;
    margin-right: 10%;
margin-left: 10%;

/* border-style: solid; */
}
}

@media screen and (min-width:1430px) and (max-width:1520px){
.whyCeplrBG{
    height: 640px;
    /* margin-top: -10px; */
}
.whyCeplr-Image{
    height: 530px;
    margin-top: 30px;
}
#Hero{
    height: 790px;
    /* border-style: solid; */
}
#whyCeplr .whyCeplrText-Container{
    width: 410px;
    margin-top:92px;
    /* border-style: solid;
    border-color: black; */
    /* position: absolute; */
margin-left: 10px;
}
}


@media (min-device-width: 320px) and (max-device-width:500px){ 
#Hero{
    display: flex;
    /* border-style: solid; */
    height: 100vh;
    /* min-height: 200vh; */
    width: auto;
    min-height: max-content;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: center;
    /* padding-bottom: 1rem; */
    flex-basis: nowrap;
}
   
.weMake-Big{
    font-size: calc(2.89vw + 2vh + 2vmin); 
    width: 95%;
    /* border-style: solid; */
    height: auto;
    line-height: 120%;
}
#whyCeplr{
    overflow: hidden;
    padding-bottom: 0rem;
}
#Hero{
    overflow: hidden;
}
  #Hero .hero-text{
       margin-top: 30%;
       /* border-color: black;
       border-style: solid; */
       height: auto;
       box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding:0px;
    /* border-color: brown;
    border-style: solid; */
    margin-left: 0px;
    width: auto;
   }
   .weMake-small{
       width:100%;
       /* border-color: orange;
       border-style: solid; */
       font-size: calc(0.7vw + 0.8vh + 1.9vmin);;
       margin-top: 24px;
   }
   #Hero .col{
    
    height: max-content;
    /* width: fit-content; */
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* padding-left: 10px;
    padding-right: 10px; */
    /* border-style: solid; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    
}
.hero-MobileImage{
    margin:  0px;
    height: 65%;
    /* aspect-ratio: 1;
    width: 200%; */
    
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
  
}
#Hero .col:nth-child(2){
    /* padding-left: 20px; */
    display: flex;
    /* align-items: center; */
    /* border-style: solid; */
    justify-content: center;
    /* padding-bottom: 3%; */
    align-items: center;
    height: auto;
}



.container{
    /* margin-left: 25px;
    margin-right: 25px; */
    /* width: 100%; */
    /* border-style: solid; */
    
}
#Hero .row{
    display: flex;
    flex-flow: column;
    /* /width:auto; */
    /* border-style: solid;
    border-color: orange; */
    /* width: 100%; */
    margin-right: 25px;
    margin-left: 25px;
}
.tryNow-btn{
    width: 100%;
    margin-top: 24px;
}

#whyCeplr{
    /* border-style: solid; */
    justify-content: center;
    align-items: center;
    display: flex;
}

#whyCeplr .container{
    /* border-style: solid; */
    display: flex;
    justify-content: center;
}
#whyCeplr .row{
    flex-flow: column-reverse;
    /* border-style: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:25px;
    margin-right: 25px;

}
#whyCeplr .col{
    /* border-style: solid; */
    /* display: flex; */
    justify-content: flex-start;
}
#whyCeplr .whyCeplrText-Container{
    /* width: 100%; */
    margin-top:12px;
    /* border-style: solid;
    border-color: red; */
    align-self: flex-start;
    margin-left: 0px;
    
    
}

.whyCeplrBigText{
    width: 100%;
    font-size: calc(1.99vw + 2vh + 2vmin);
    font-family: Asap;
    font-weight: bold;
    line-height: 120%;
    
    /* margin-top:10px; */
    /* border-style: solid; */

}
.whyCeplr-smallText1{
    font-size: 14px;
    width: 100%;
    margin-top: 24px;
}
.whyCeplr-linksDiv{

    width:100%;
    display:flex;
    flex-flow:column;
    /* justify-content: space-around; */
    margin-top: 24px;
    margin-left: 0;
}
.whyCeplr-linksDiv1{
    width:100%;
    display:flex;
    flex-flow:column;
    margin-top: 0px;
    margin-left: 0;
}
.whyLink1,.whyLink2{
    margin-top: 10px;
}
.whyCeplr-Image{
    margin:  0px;
    height: 100%;
    /* aspect-ratio: 1;
    width: 200%; */
    
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    /* align-self: flex-start; */
    /* margin-left: -8%; */
    /* border-color: orange;
    border-style: solid; */
}
.hero-Image{
    display: none;
}
#whyCeplr .col:nth-child(1){
    /* border-style: solid; */
    padding-top: 30px;
}

#Mobile{
    /* border-style: solid; */
    justify-content: center;
    padding-bottom: 1rem;
}
#Mobile .container{
    /* border-style: solid;
    border-color: red; */
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    ;
}
#Mobile .row{
    width: auto;
    padding:0;
    margin-left:15px;
    margin-right: 15px;
}
.howDoesitContainer{
    /* border-style: solid; */
    width: 100%;
}
.howDoes-BigText{
    width: 100%;
    /* border-style: solid; */
    border-color: blue;
    font-size: calc(3vw + 1.2vh + 1.64vmin);
    line-height: 120%;
    flex-basis: unset;
}
.text-container{
    /* border-style: solid;
    border-color: chartreuse; */
    margin-top: 54px;
    width: 100%;
}
.big-text{
    /* border-style: solid;
    border-color: red; */
    font-size: 16px;
    gap:25.5px;
    width: fit-content;
    margin-left: 10px;
}
.round-container{
    margin-top: 22px;
}
#Mobile .col:nth-child(2){
    /* border-style: solid;
    border-color: black; */
    display: flex;
    align-items: flex-start;
    justify-content: center;

}

.small-text{
    font-size: 15px;
    /* width: 100%; */
    /* border-color: rebeccapurple;
    border-style: solid; */
    margin-top: -10px;
    margin-left: 55px;
    width: 100%;
}


 }

 @media screen  and (min-device-width:400px) and (max-device-width:480px){
    
     .howDoes-BigText{
         font-size: 27.5px;
         width: 400px;
         line-height: 34px;
     }
     .small-text{
        font-size: 15px;
        /* width: 100%; */
        /* border-color: rebeccapurple;
        border-style: solid; */
        margin-top: -10px;
        margin-left: 55px;
        
    }

    .big-text{
        gap: 27px;
    }
   

    
 }
 @media screen  and (min-device-width:360px) and (max-device-width:380px){
    
     .small-text{
        font-size: 14px;
        width: 100%;
        /* border-color: rebeccapurple;
        border-style: solid; */
        margin-top: -8px;
        margin-left: 55px;

    }
     
 }


 @media screen  and (min-device-width:320px) and (max-device-width:370px){
    .small-text{
        font-size: 12.4px;
        width: 220px;
        /* border-color: rebeccapurple;
        border-style: solid; */
        margin-top: -10px;
        margin-left: 52px;

    }
   
    .big-text{
        /* border-style: solid;
        border-color: red; */
        font-size: 14px;
        gap:26.7px;
        width: fit-content;
        margin-left: 10px;
    }
   
   
   
 }


 @media screen and (min-device-width:375px) and (max-device-width:480px) {

    #Mobile{
        /* border-style: solid; */
    }
    #Mobile .container{
        /* border-style: solid; */
    }
    #Mobile .row{
        /* border-style: solid; */
    }
    
     .text-container{
         /* border-style: solid; */
     }
     .big-text{
         /* border-style: solid; */
         gap: 30px;
     }
     .small-text{
             /* border-style: solid; */
         width: 54%;
         /* margin: 0; */
         margin-left: 55px;
         margin-top: -10px;
         font-size: 15px;
     }
     
 }


 @media  only screen and (width:360px) {
     
    .hero-Image{
        /* margin-top: -10px; */
    }
 }
 @media  only screen and (min-height:737px) and (max-height:920px) {
     
    .hero-MobileImage{
        height: 60%;
    }
    
    
   
 }
 