/* <-footer Section-> */

#Footer{
     background: #5A5858;;
     background-size: cover;
    display:flex;
    align-items: center; 
     justify-content:center;
    overflow:hidden;
    flex-direction: column;
    height: auto;
    padding: 10px 10px;
}

#Footer .row{
    padding: 70px 0px 00px 40px ;
    /* border-color: purple;
    border-style: solid; */
    align-items: flex-start;
    justify-content: center;
    display: flex;
    /* border-color: purple;
    border-style: solid; */
}

.text-uppercase{
    color: white;
    /* border-color: yellowgreen;
    border-style: solid; */
    height: auto;
}

.text-dark{
    /* border-color: crimson;
    border-style: solid; */
    /* margin-bottom: 0; */
    padding:0px;
    margin: 0px;
    list-style: none;
}
#Footer .col{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin: 30px; */
    text-align: left;
    width: 240px;
    box-sizing: border-box;
    /* border-color: crimson;
    border-style: solid; */
    height: 200px;
    justify-content: start;
    /* border-style: solid; */

    
}

#Footer .col:nth-child(1){
    /* border-color: teal; */
    width: 290px;
    justify-content: flex-start;
    align-items: flex-start;
    
}
#Footer .col:nth-child(2){
    border-color: teal;
    width: 160px;
    /* padding: 0px; */
    /* margin-left: 80px; */
    /* border-style: solid; */
    margin-left: 250px;
}
#Footer .col:nth-child(3){
    padding-left: 40px;
}

#Footer .list-unstyled{
    /* border-style: solid ;
    border-color: yellow; */
    /* height: 100px; */
    /* margin-top: -100px; */
    display: flex;
    /* flex-basis: auto; */
    flex-flow: column;
    position: absolute;
    height: 140px;
    margin:0px;
    list-style: none;

    
}

#CopyRIght{
    background: #F3F3F3;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Footer-Logo{
    height: 51px;
}
.footer-logo{
    height: 41px;
    margin-top: -10px;
}

#Footer p{
    font-size: 14px;
    color: white;
    font-family: Asap;
    font-weight: normal;
    margin-left: 10px;
    margin-top:10px ;

}

.list-style{
    /* color: crimson; */
    margin: 10px;
    margin-bottom: 0;
    /* border-style: solid;
    border-color: cyan; */
    height: 30px;
    align-self: flex-start;
    text-decoration: none;
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #FFFFFF;
cursor: pointer;
}

.list-style:hover{
    color: #FFFFFF;
    text-decoration: underline;
}

#Footer h2{
    /* border-color: darkblue;
    border-style: solid; */
    height: auto;
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 21px;
/* identical to box height */


color: #FFFFFF;
margin-left: 8px;
}

@media screen and (min-width:1400px) {
  
   #Footer .col{
    
    width: 260px;
    /* border-color: brown; */

   

    
}
#Footer .col:nth-child(1){
    /* border-color: teal; */
    width: 290px;
}
#Footer .col:nth-child(2){
    /* border-color: teal; */
    width: 180px;
    /* padding: 0px; */
    margin-left: 290px;
    /* border-style: solid; */
}

}
@media screen and (min-width:1520px) {
  
   #Footer .col{
    
    width: 290px;
    /* border-color: aqua; */
   

    
}
#Footer .col:nth-child(1){
    border-color: teal;
    width: 310px;
    padding: 0px;
}
#Footer .col:nth-child(2){
    border-color: teal;
    width: 210px;
    /* padding: 0px; */
    margin-left: 360px;
}


}

@media screen and (min-device-width:320px) and (max-device-width:500px) {
    
    #Footer{
        /* border-style: solid; */
        padding-left: 0;
        padding-right: 0;
    }

    #Footer .container{
        /* border-style: solid; */
        margin: 0;
        width: 100%;
    }
    #Footer .col:nth-child(3){
        padding-left: 0px;
    }
    #Footer .row{
        /* border-style: solid;
        border-color: blue; */
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-flow: column-reverse;
        padding-top: 30px;
    }

    #Footer .col:nth-child(2){
        /* border-style: solid; */
        margin-left: 0;
    }

    #Footer .col{
        margin-left: 0;
        padding-left: 0;
        margin: 0;
    }

    .list-style{
        /* margin-left: 0; */
    }
    .footer-logo{
        height: 35px;
        margin-left: 7px;
    }
}