#FirstScreenBlank1{
    height: 100%;
    background: transparent;
    /* width: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
    /* box-sizing: border-box;
    -webkit-box-sizing: border-box; */
    
}

#FirstScreenBlank1::-webkit-scrollbar {
    display: none;
  }
#FirstScreenBlank1 .row{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border-style: solid; */
    /* border-color: yellow; */
    /* padding-right: 20px;
    padding-left: 20px; */
}

.ConnectHeader{
    width:100%;
    height:60px;
    /* border-bottom: 1px solid red; */
  }
.connectToCeplr-btn{
    background-color: #3a86ff;
    color: #fff;
    border: 1px solid var(--primary);
    /* transition: all .3s ease-out; */
    width: 190px;
    height: 46px;
    font-size: 16px;
    /* border-radius: 2px; */
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 29px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
 }

@media screen and (min-width:200px) and (max-width:8090px) {

    #FirstScreenBlank1{
        /* height: 100vh; */
        width: auto;
        /* border-style: solid; */
        height: 100vh;
        overflow: scroll;
        padding-bottom: 16rem;
        
                
    }
    #FirstScreenBlank1 .row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* border-style: solid; */
        /* border-color: yellow; */
        /* padding-right: 20px;
        padding-left: 20px; */
        flex-flow: column;
        padding-bottom: 40rem;
    }
    .first{
        /* height: 100vh; */
        background:white;
        display: flex;
        /* height: 100%; */
        /* min-height: 100vh; */
        width: 100%;
        flex-direction: column;
        /* border-style: solid; */
        /* padding-bottom: 30rem; */
        padding:0;
        overflow: scroll;
    }
        .modal-content{
        /* background: yellow; */
        display: flex;
        border-style: none;
        box-shadow: none;
        padding-left:0
       
    }
    .modal-header{
        padding:0;
        padding-top: 10px;
        border-style: none;
        height: min-content;
    }
    .close{
        background: transparent;
        border-style: none;
        margin-left: 10px;
    }
    .modal-body1{
        /* border-style: solid; */
        display: flex;
        /* height: 60vh; */
        flex: auto;
        position:relative;
      bottom:0;
      display:block;  
      width:100%;
      height: min-content;
      padding:0
    }
    .modal-footer1{
        /* display: "flex"; */
         flex-direction: "row";  
         ;
         border-style: none;
         height: max-content;
         position:relative;
      bottom:0;
      display:block;  
      width:100%;
      align-self: flex-end;
      justify-self: flex-end;
      margin-top: 50px;
      background: #F3F3F3;
      align-items: center;
      justify-content: center;
      font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
text-align: center;

color: #5F5F5F;
height: 50px;
    }

    .consentlogo{
        height: 25px;
        margin-left: 10px;
    }
    .Name-div{
        /* border: 1px solid red; */
        width: 100%;
        height: fit-content;
        padding: 20px;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }
    .purpleCirc{
        height: 80px;
        background: #C45ACD;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .yellowCirc{
        height: 80px;
        background: #DDB05A;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .arrow-div{
        height: 80px;
        aspect-ratio:1;
        /* border:1px solid black; */
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
    .consentArrow{
        height: 25px;
    }
    .nameImage{
        height: 25px;
    }
    .nameImage1{
        height: 40px;
    }
    .modalText-container{
        display: flex;
        margin-top: 10px;
        padding:20px;
        /* border-style: solid; */
        flex-flow: column;
        height: fit-content;
    }
     .first h2{
    font-family: Asap;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    height: fit-content;
    color: #3D3D3D;
   }
  .first p{
    font-family: Asap;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    
    color: #000000;
    /* border: 1px solid black; */
    margin-left: 0;
   }

   .benefit-div{
       /* border: solid; */
       width: 100%;
       height: fit-content;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-top: 20px;
       height: fit-content;
       /* border: 1px solid black; */

   }
   .benefit{
       height: 40px;
       width: 100px;
       display: flex;
       align-self: center;
       font-family: Asap;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 27px;
background: #EEEEEE;box-shadow: 0px 0px 4px 5px rgba(177, 177, 177, 0.25);
/* border-radius: 10px 10px 0px 0px; */
color: #2B2B2B;
align-items: center;
justify-content: center;
height: fit-content;

       
   }
   .grayDiv{
    background: #FAFAFA; 
display: flex;
height: max-content;
width: 100%;
padding-top: 30px;
margin-bottom: 30px;
margin-top: 50px;
/* padding-bottom: 20px; */
/* margin-left: 0; */
padding-left: 20px;
height: fit-content;
  }
  .modalList{
    font-family: Asap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    
    color: #2B2B2B;
    padding-bottom: 20px;

  }
  .modalConnectButton{
    background: #3A86FF;

    color: #fff;
    border: 1px solid var(--primary);
    /* transition: all .3s ease-out; */
    width: 80%;
    height: 46px;
    font-size: 16px;
    /* border-radius: 2px; */
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 9px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
    align-self: center;
    /* display: flex; */
    margin-bottom: 30px;
  }
  .modalConnectButton1{
    background: #3A86FF;

    color: #fff;
    border: 1px solid var(--primary);
    /* transition: all .3s ease-out; */
    width: 80%;
    height: 46px;
    font-size: 16px;
    /* border-radius: 2px; */
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 29px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
    align-self: center;
    /* display: flex; */
    margin-bottom: 30px;
  }
  .buttonDiv{
      height: 50px;
      display: flex;
      justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    margin-top: 85px;
  }

  .connectToCeplr-btn{
      width:80%
  }
  .ConnectHeader{
      width:100%;
      height:60px;
      /* border: 1px solid red; */
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;
      /* justify-content: space-between; */
    }
    .arrowHeader{
     
        height:20px;
        /* border: 1px solid; */
        width: 20px;
        display: flex;
        /* justify-self: self-end; */
        /* margin-top:-510%; */
        position: absolute;

    }
    
    .incomeHeader{
        display: flex;
        flex-flow: column;
        width:200px;
        justify-content: center;
        align-items: center;
        height: 60px;
        font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
/* identical to box height */

text-align: center;

color: #3D3D3D;;
/* border: 1px solid; */
align-self: center;
/* justify-self: flex-end; */
    }
    .stepDiv{
        width: 100%;
        height: 70px;
        margin-top: 10px;
        /* border:1px solid blue; */
        padding: 0px;
        align-self: flex-end;
        justify-self: flex-end;
        /* position: absolute; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .circleDiv{
        height: 200px;
        width: 200px;
        background: #C4C4C4;

        margin-top: 30px;
        border-radius:50%
    }

    #FirstScreenBlank1 h2{
        font-family: Asap;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        height: fit-content;
        color: #3D3D3D;
        margin-top: 30px;
       }
      #FirstScreenBlank1 p{
        font-family: Asap;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        
        color: #000000;
        /* border: 1px solid black; */
        margin-left: 0;
        margin-top: 12px;
       }
       .MuiStepConnector-lineHorizontal{
           color:blue
       }
       .MuiStepConnector-line{
           border-color: blue;
       }

       
      
    
       .MuiStepIcon-root{
           
           /* border: 5px solid black;
           border-radius: 50%; */
       }

    .MuiStepIcon-text{
        display: none!important;

    }
    
    .MultiSetpIcon-circle{
background: transparent !important;
    }
   
    
.step-wrapper{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    width: 23%;
    /* border: 1px solid black; */
    /* padding-left: 26px; */
    margin-top: 20px;
}

.stepNumber{
    border-radius: 50%;
    
    width:30px;
    height:30px;
    text-align: center;
    display: flex;
    position: relative;
    

}
.dividerLine{
    position:absolute;
    height: 3px;
    background: gray;
    width: 19%;
    margin-top:14px;
    /* left: 1%; */
    /* left:10% */
    /* right: 11%; */
    margin-left: 22%;
}

.dividerLine-active{
    background: #DD802A
}
.stepNumber-active{
     background: #DD802A;
    ;
    color:#878787;
    align-items: center;
    justify-content: center;
}
.stepNumber-disabled{
    border:7px solid #DD802A;
}

.checkMarkClass{
    height:15px
}
.modal.fade .modal-dialog {
    transform: translate3d(0, 55vh, 0);
    transition: ease-in-out 1sec ;
    display: flex;
    align-items: flex-end;
  }
.modal.in .modal-dialog {
    transform: translate3d(0, 0, 0);
  }
  

  .modal-dialog {
    position: relative;
    /* width: auto; */
    margin: 0rem;
    pointer-events: none;
    height: 45%;
    width: inherit !important;
    /* border-color: red;
    border-style: solid; */
    display: flex;
    align-self: flex-end;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    
}
.modal-header{
    padding-right: 30px;
    padding-top: 30px;
    /* border: 1px solid red; */
}
.modal-content{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.modal-body{
    /* border: 1px solid black; */
    height: 10%!important;
    margin-top: 1%;
    /* margin-bottom: 50px; */
    display: flex;
    flex-direction: column;
}

.modal h2{
    height: 10%;
    /* border: 1px solid red; */
}

.mobileInputModal{
    height: 50px;
    margin-top: 10%;
    width: 98%;
}

.modalConnectButtonmobileModal{
    background: #3A86FF;

    color: #fff;
    border: 1px solid var(--primary);
    /* transition: all .3s ease-out; */
    width: 100%;
    height: 46px;
    font-size: 16px;
    /* border-radius: 2px; */
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 11%;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
    align-self: center;
    /* display: flex; */
    margin-bottom: 30px;
}

.modal-footer{
    flex-direction: "row";  
    ;
    border-style: none;
    height: max-content;
    position:relative;
 bottom:0;
 display:block;  
 width:100%;
 align-self: flex-end;
 justify-self: flex-end;
 /* margin-top: 50px; */
 background: #F3F3F3;
 align-items: center;
 justify-content: center;
 font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
text-align: center;

color: #5F5F5F;
height: 50px;
}
  }



  @media screen and (min-width:440px ) and (max-width:580px){
    .dividerLine{
        position:absolute;
        height: 3px;
        background: gray;
        width: 19%;
        margin-top:14px;
        /* left: 1%; */
        /* left:10% */
        /* right: 11%; */
        margin-left: 22%;
        /* background: ; */
    }
    .dividerLine-active{
        background: #DD802A
    }
  }