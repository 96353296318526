#ConsentFooter{
    height: 60px;
    display: none;
}
#ConsentFooterWelcome{
    display: none;
}

.welcomeFooter{
    height: 60px;
    background: white;
}