.sub-menucontainer{
    height:auto;
    width:300px;
    padding-bottom: 8px;
    /* padding-left: 20px; */
    /* padding-right: 90px; */
    
    display: flex;
    /* flex-direction: row; */
    /* border-style: solid;
    border-color: aqua; */
    /* align-self: flex-end; */
    /* margin-left: 199px; */
    padding-top: 8px;
    justify-content: space-between;
    align-items: center;
    /* background-color: white;\ */
    
    
    
    
    
    
    

}

.sub-menucontainer a{
    text-decoration: none;
}
.sub-menucontainer:hover{
    background: rgba(103, 103, 103, 0.1);
    cursor: pointer;
}
.hash-class{
    text-decoration: none;
}
.sub-image-container{
height:30px;
width:20px;
/* border-style: solid; */
/* margin-top: -5px; */
margin-right: 20px;




}
.sub-image{
    height:100%;
    width: 100%;
    margin-top:13px;
    float: right;
    align-self: flex-end;
    display: flex;
}
.title-text{
    font-size: 15px;
    color: black;
    font-weight: 600;
    /* margin-top: -35px; */
    /* margin-left: 54px; */
    width:auto;
    height: 25px;
    
    display: flex;
    align-items: flex-start;
    font-family: Montserrat ;
    /* border-style: solid;
    border-color: chocolate; */
    /* position: absolute; */
    float: left;
    margin-left: 20px;
    
}

.title-text:hover{
    /* color: #3A86FF;
    
    cursor: pointer; */
}
.small-subTextContainer{
    height:35px;
    width:222px;
    
    margin-left: 54px;
    padding:0;
    display: flex;
    /* align-items: flex-start; */
    
    flex-direction: row;
    text-align:start;
    
    position: absolute;
    color:#0007;
    font-size: 11px;
    margin-top:26px;
    /* border-style: solid;
    border-color: #202222; */
    line-height: 13px;
    font-family: Montserrat ;

}
