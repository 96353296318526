#Cards{
    /* min-height: 680px; */
    /* height: 500px; */
    height: auto;
    
    background: rgba(58, 134, 255, 0.03);    background-size: cover;
    display:flex;
    /* align-items: center; */
    justify-content:center;
    overflow:hidden;
    /* border-color: orchid;
    border-style: solid; */
    padding-bottom: 1rem;
    background-color: #3A86FF08;
;
}

#Cards .col{
    padding: 0 50px 0 5px;

/* border-style: solid; */
}
#Cards1{
    height: auto;
    
    background:#FFF no-repeat scroll left center;
    background-size: cover;
    display:flex;
    /* align-items: center; */
    justify-content:center;
    overflow:hidden;
    /* border-color: orchid;
    border-style: solid; */
    padding-bottom: 3rem;
}
#Cards1 .container{
    /* border-style: solid; */
}
 #Cards h2{
    /* width: 210px; */
    font-size: 30px;
    font-family: Asap ;
     font-weight: bold;
     font-style: normal;
     line-height: 23px;
     color: #222222;
     align-self: center;
     justify-content: center;
     /* margin-top:10px; */
     /* border-style: solid;
     border-color: #222222; */
     /* margin-left: 14rem */
     text-align: center
    ;
margin-top: 45px;
margin-left: -75px;
}

#Cards p{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 23px;

color: #222222;
}

.cardBig-Text{
    /* height:30px; */
    width: 480px;
    font-size: 32px;
    font-family: Asap;
     font-style: normal;
     font-weight: bold;
     line-height: 23px;
     color: #222222;
     align-self: center;
     text-align: start;
     /* margin-right: 100px; */

     margin-top:10px;
     /* border-style: solid;
     border-color: #222222
    ; */
}
.card{
    border-color:none;
    border-style: none;
    width: auto;
    /* margin-left: 30px; */
    background-color: white;
    padding-right: 10px;
    padding-left: 10px;

    
}
.card2{
    /* border-color:none;
    border-style: solid; */
    width: auto;
    /* margin-left: 70px; */
    background-color: white;
    padding-right: 50px;

    
}

.cardbig-useText{
    font-size: 18px;
    color: #202222;    ;
    font-weight: 700;
    height:auto;
    width: auto;
    display: grid;
    grid-template-columns: 8% auto ;
    ;
    grid-column-gap: 35px;
    
    margin-left: 10px;
    /* border-style: solid;
    border-color: limegreen; */
    /* margin-left: 10px; */
    align-items: center;
    
  }
  
 
  .cardround-container2{
    background-color:  transparent;
    border-radius: 50%;
    height:50px;
    /* width: auto; */
    aspect-ratio: 1;
    /* margin-top: 25px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-style: solid; */
  
  
  
  
    
  }
  .cardcircleClass{
position: absolute;
height: 50px;
aspect-ratio: 1;
  }

  .cardActualIconClass{
position: relative;
/* margin-top: -10px; */
height: 25px;

  }

.cardImage{
    width:400px;
    height: auto;
    /* border:1px black solid; */
    display: flex;
    /* justify-content: space-between; */
    /* clear: both */
align-items: center;
box-sizing: border-box;
-webkit-box-sizing: border-box;
    
}
.happyimage{
    position: relative;
    height: 20px;
    /* margin:15px; */
    /* border-style: solid; */
    box-sizing: border-box;
-webkit-box-sizing: border-box;
display: flex;
align-self: center;
margin-left: 15px;
}

#Cards h4{
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 23px;

color: #222222;
float: right ;

/* margin-top: 20px; */
 border-style: solid;
border-color: blue; 
/* width: auto; */
/* position: initial; */
/* margin-left: 20px; */
position: relative;
margin-left: 5%;
}

 .text{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;

color: #1f1f1f;
/* border-style: solid;
border-color:black; */
width: 450px;
margin-top: 10px;

}
.talkUs{
    background-color: transparent;
    color: #3A86FF;
    /* border: 1px solid #3a86ff; */
    transition: all .3s ease-out;
    width: 190px;
    /* height: 116px; */
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    font-weight: 500;

    position: relative;
    margin-top: 80px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
    /* margin-left: 20px; */
    text-decoration: none;
 }

 .talk{
     margin-top: 10px;
 }

.blue-text{
    width: 140%;
    /* border-color:yellow;
    border-style: solid; */
    margin-top:10px;
    position: absolute;
    height: 30px;
    padding-top: 0px;
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
/* identical to box height */
text-decoration: none;


color: #3A86FF;
}
.rectangle-image{
    position: absolute;
    /* width: 10%; */
height: 50px;
background: #3A86FF;
/* border: 1px solid black; */
/* aspect-ratio: 1; */

    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.cardVectorClass{
    margin-left: 5px;
    height: 10px;
}
#Cards .container{
    /* border-style: solid; */
    /* padding-left: 70px;
    padding-right: 20px; */
    
}
#Cards .row{
    margin-top: 50px;
    /* border-color: chocolate;
    border-style: solid; */
    display: flex;
    justify-content: space-between;
    /* gap:10px */
    
    
}

#Cards .col{
    /* border-style: solid;
    border-color: blue; */
    /* gap: 20px; */
    padding-bottom: 50px;
}

@media screen and (min-width:1410px) and (max-width:1520px) {
    
        .card2{
            /* border-color:none;
            border-style: solid; */
            width: 560px;
            margin-left: 120px;
            background-color: white;
        
            
        }
        .card{
            border-color:none;
            border-style: none;
            width: auto;
            margin-left: 30px;
            background-color: white;
        
            
        }
    
        #Cards h2{
            font-size: 37px;
        }
}


@media screen and (min-width:1520px) and (max-width:1800px){
     .text{
        font-size: 19px;
        line-height: 30px;
        /* border-style: solid; */
        width: 520px;
    }
    .blue-text{
        /* border-style: solid; */
        font-size: 19px;
    }
    .cardVectorClass{
        height: 12px;
    }
    #Cards{
        padding-bottom: 1rem;
    }
    #Cards .container{
        /* padding-left: 100px; */
    }
    #Cards h4{
        font-size: 21.2px;
        /* border-style: solid; */
        width: 540px;
        line-height: 28px;
    }
    #Cards p{
        font-size: 26px;
    }
    #Cards h2{
        font-size: 47px;
        margin-top:45px;
        margin-left: -75px;
    }
    #Cards .row{
        margin-top:70px;
        /* border-style: solid;
        border-color: aqua; */
    }
    .card2{
        /* border-color:none;
        border-style: solid; */
        width: 560px;
        margin-left: 140px;
        background-color: transparent;
    
        
    }
    .card{
        border-color:none;
        border-style: none;
        width: 560px;
        margin-left: 30px;
      
    
        
    }
    .rectangle-image{
        height:60px;
        aspect-ratio: 1;
    }
}

@media screen and (min-width:1120px) and (max-width:1390px) {
    #Cards .container{
        /* padding-left: 20px; */
    }
    
}



@media (min-device-width: 320px) and (max-device-width:500px){
    #Cards{
        justify-content: center;
        box-sizing: border-box;
-webkit-box-sizing: border-box;
    }
    .cardbig-useText{
        font-size: 15px;
        margin-left: 5px;
        grid-column-gap: 32px;
        
    }
    #Cards .container{
        width: 100%;
    margin-left: 0;
    margin-right: 0;
    /* border-style: solid; */
    box-sizing: border-box;
-webkit-box-sizing: border-box;
    }
    #Cards .row{
        width: auto;
        padding:0;
        margin-left:15px;
        margin-right: 15px;
        /* border-style: solid; */
        margin-top: 38px;
        padding:0px
    }
    #Cards .col{
        /* border-style: solid;
        border-color: lawngreen; */
        /* padding-right: 10px; */
    }
    .text-center{
        /* border-style: solid; */
        margin-left: 25px;
        margin-right: 20px;
        width: auto;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    #Cards h2{
        width: inherit;
        text-align: start;
        /* border-style: solid; */
        margin-left: 0px;
        font-size: calc(2.89vw + 1.3vh + 1.84vmin);
        line-height: 130%;
    }
    .card{
        width: 100%;

        /* border-style: solid;
        border-color: black; */
        margin: 0px;
        padding:0;
        background-color: white;
        padding-left: 0px;
        padding-right: 0px;
        box-shadow: 0px 6px 20px rgba(159, 159, 159, 0.25);
border-radius: 4px;
box-sizing: border-box;
-webkit-box-sizing: border-box;
    }
    .card-body{
        /* border-style: solid; */
        width: auto;
    }
    .cardImage{
        /* border-style: solid; */
        width: auto;
        
    }
    .rectangle-image{
        height: 50px;
        aspect-ratio: 1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    h4{
        /* border-style: solid; */
        width: auto;
        font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 18px;

color: #141414;
    }
    .text{
        /* border-style: solid; */
        width: auto;
        font-size: 14px;
    }
    .talk{
        /* border-style: solid; */
    }
    #Cards .col{
        /* border-style: solid;
        border-color: black; */
        /* gap: 20px; */
        padding-bottom: 24px;
        /* padding:0; */
        /* width: 120% */
        /* height: 100%;
        width: 100% */
        ;
        /* padding-right: 70px; */
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 auto;

        flex-basis:100%;
        
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        padding-right: 0; 
        padding-left: 0;
        /* offset: none; */
    }
    
}




