.developers-CardContainer{
    height:auto;
    width:501px;
    /* border-color: black;
    border-style: solid; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
    /* margin-left: 115px; */
    /* align-self: center; */
    /* box-shadow: 0px 8px 20px rgba(201, 201, 201, 0.25); */
    box-sizing: border-box;
    /* border: 1px solid #E9E9E9; */
    background: transparent;
    /* border-radius: 15px; */
    margin-top: 100px;
    



}
.headingText{
    /* width: 210px; */
 font-size: 37px;
 font-family: Asap ;
  font-weight: bold;
  font-style: normal;
  line-height: 39px;
  color: #222222;
  /* align-self: center;
  justify-content: center; */
  /* margin-top:10px; */
  /* border-style: solid; */
  border-color: #222222;
  /* margin-left: 14rem */
  text-align: start
 ;
 /* border-style: solid; */
 width: 300px;
 margin-top: 0px;
 height: auto;
}
.developers-imageContainer{
    height:207.59px
;
    width:275.04px
    ;

    /* border-style: solid; */
    /* margin-left: 50px; */
    /* border-color: black; */
    align-self: center;
    margin-top: 25px;
}

.developer-image{
    height:100%;
    width: 100%;
}
.forDevelopers-BigText{
    height:auto;
    width:90%;
    /* border-color: black;
    border-style: solid; */
    font-family: Asap;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #222222;
    margin-left: -50px;
    align-self: center;
    margin-top: 30px;
    text-align: start;
    /* border-style: solid; */
}

.forDevelopers-smallText{
    height:15%;
    width: 100%;
    /* border: solid;
    border-color: black; */
    font-family: Asap;
    font-style: normal;
    font-size: 16px;
    text-align: start;
    color: #1f1f1f  ;
    /* margin-left: 24px; */
    margin-top: 20px;
    line-height: 24px;
    font-weight: 300;
    /* border-color: limegreen;
    border-style: solid; */
    margin-left: 1px;
    

}

.learn-class{
    width: 470px;
    height: auto;
        /* text-align: start; */
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 23px;

color: #3A86FF;
cursor: pointer;
margin-top: 23px;
/* margin-left: 24px; */
/* border-style: solid; */
align-items: center;
display: flex;
margin-left: 0px;
}

.blue-class{
    margin-left: 5px;
    height: 10px;
}
/* /*  */
.link1{
    float:left ;
    ;
}
.link2{
    float:right ;
    ;
    margin-right: 70px;
}
.documentation{
    background-color: #3a86ff;
    color: #FFF;
    border: 1px solid #3a86ff;
    transition: all .3s ease-out;
    width: 190px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 0px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
 }
 .sandbox{
    background-color: transparent;
    color: #3A86FF;
    border: 1px solid #3a86ff;
    transition: all .3s ease-out;
    width: 190px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 0px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
    margin-left: 20px;
 }
@media screen and (min-width:1520px) and (max-width:1800px){
    .developers-CardContainer{
        height: auto;
        width: 601px;
    }
    
    .developers-imageContainer
    {
        height:307.59px
;
    width:355.04px
    ;
    }
    .forDevelopers-BigText{
        font-size: 21.2px;
    }
    .forDevelopers-smallText{
font-size: 18px;
line-height: 30px;
margin-top: 20px;
width: 93%;
    }
    .learn-class{
        font-size: 19px;
        /* border-style: solid; */
        /* display: flex; */
        width: 570px;
        margin-top: 20px;
    }
    
    .headingText{
        font-size: 48px !important;
        line-height: 53px;
        width: 350px;
    }
}



@media screen and (min-width:1430px) and (max-width:1520px){
    
}


@media screen and (min-device-width:320px) and (max-device-width:500px) {

    .developers-CardContainer{
        /* border-style: solid; */
        width: 100%;
        margin:0px
    }
    .headingText{
        font-size: calc(2vw + 1.9vh + 2vmin);
        /* border-style: solid; */
        width: 100%;
    }
    .forDeveleopers-Button{
        width:100%
    }
    .documentation{
        width: 50%;
        /* display: flex; */
        font-size: calc(0.5vw + 1vh + 1vmin);
    }
    .sandbox{
        width:50%
        ;
        font-size: calc(0.5vw + 1vh + 1vmin);
    }
    .forDeveleopers-Button{
        /* border-style: solid; */
        width: 100%;
    }
    .learn-class{
        /* border-style: solid;
        border-color: red; */
        width: 100%;
    }
}
