@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  
}

html, body, #app, #app>div{
  /* height: 100%;
  width: 100%;
  box-sizing: border-box; */
  font-family: Asap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center
  ;}

  /* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */


  * {
    box-sizing: border-box;
    /* margin: 0;
    padding: 0;
    font-family: Montserrat; */
    /* overflow-y:none; */
    /* overflow-wrap: unset; */
    /* overflow-x: hidden; */
    height: 100%;
    /* width: 100%; */
    /* align-items: center; */
  /* justify-content: center; */
  
    
  }
  
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, Montserrat,'Courier New',
      monospace;
  }
  