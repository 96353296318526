#AboutUsBanner{
    /* min-height: 800px; */
    /* height: 100vh; */
    /* max-height: 1290px; */
    /* height: 1%; */
    height: 204px;
    
    background: rgba(71, 71, 71, 0.34);     background-size:cover;
    background-image:url('../../../../images/AboutUsBanner.png');
    /* background-position: 0 20, 0 0; */
background-repeat: no-repeat, no-repeat;
    display:flex;
    overflow:hidden;
    align-items: center; 
    /* justify-content:center;
    /* flex-direction: column; */
    z-index: 0;
    margin-top: 70px;

}

#AboutUsBanner h1{
    margin-top: 70px;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 36px;
/* line-height: 38px; */

color: #FFFFFF;
/* width: 300px; */
text-align: center;
}

#About1section{
    height: auto;
    /* min-height: 100px; */
    background:#FFFF no-repeat scroll left center;
    background-size: cover;
    display:flex;
    /* align-items: center; */
    justify-content:center;
    overflow:hidden;
    flex-direction: column;
    /* border-color: lawngreen;
    border-style: solid; */
    padding-bottom: 9rem;
}

.aboutUscontainer{
    /* border-style: solid; */
    /* display: flex; */
    margin-top: 140px;
}

.aboutUsHeading{
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 55px;
/* identical to box height */


color: #141414;
}

.AboutUsTextSmall{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 26px;

color: #000000;
width: 470px;
margin-top: 30px;
}

.aboutUsImage{
    margin-top: 80px;
    margin-left: -90px;
}



@media screen and (min-width:1520px) and (max-width:1800px){
    .aboutUsHeading{
        font-size: 50px;
    }
    .AboutUsTextSmall{
font-size: 18.4px;
width: 530px;
line-height: 32px;
    }
    .aboutUsImage{
        height: 440px;
        margin-left: -30px;
    }

    #AboutUsBanner{
        height: 255px;
    }
}

@media screen and (min-width:1280px) {
    #AboutUsBanner h1{
        display: none;
    }
}

@media screen and (min-width:320px) and (max-width:500px) {
    #AboutUsBanner{
        /* border-style: solid; */
        /* height: 204px; */
        width:100%;
        height: min(18.9vh);        background: rgba(71, 71, 71, 0.34);     background-size:cover;
        background-image:url('../../../../images/AboutUsBannerMobile.png');
        /* background-position: 0 20, 0 0; */
    background-repeat: no-repeat, no-repeat;
        display:flex;
        overflow:hidden;
        align-items: center; 
        /* justify-content:center;
        /* flex-direction: column; */
        z-index: 0;
        margin-top: 70px;
    }
    #AboutUsBanner .container{
        display: none;
    }
    #AboutUsBanner h1{
        width: 100%;
        /* border-style: solid; */
        margin: 0;
        font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 23px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
    }
    #AboutUsBanner .row{
/* border-style: solid; */
    }

    #About1section{
        /* border-style: solid; */
        padding-bottom: 1rem;
    }
    #About1section .row{
        margin-left: 15px;
        margin-right: 15px;
        display: row;
        flex-direction: column;
    }
    .aboutUscontainer{
        margin:0
    }
    .aboutUsHeading{
        width: 100%;
        font-size: 32px;
        margin-top: 20px;
    }
    .AboutUsTextSmall{
        width: 100%;
        font-size: 14px;
        margin-top: 0px;

    }
    .aboutUsImage{
        width: 100%;
        height: 10%;
        margin-left:0;
        margin-top: 20px;
    }
}