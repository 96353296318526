
.header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 999;
    -webkit-transition: .3s;
    transition: .3s;
    /* background: #FFF;
    border:1px solid #dcdcdc; */
    min-height: 70px;
    height: auto;
    z-index: 999;
    /* border-style: solid; */
}
.header1{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 999;
    -webkit-transition: .3s;
    transition: .3s;
    background: #FFF;
    border:1px solid #dcdcdc;
    min-height: 70px;
    height: auto;
}


.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    /* z-index: 999; */
    -webkit-transition: .3s;
    transition: .3s;
    /* background: #FFF;
    border:1px solid #dcdcdc; */
    min-height: 70px;
    height: auto;
}

.navbar1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 999;
    -webkit-transition: .3s;
    transition: .3s;
    /* background: #FFF;
    border:1px solid #dcdcdc; */
    height: 70px;
    /* height: auto; */
    /* justify-content: space-between; */
    display: flex;
    border-style: solid;
}

.container{
    /* border-style: solid;
    border-color: brown; */
    padding: 0;

}
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
.navbar-nav{
    /* border-style: solid;
    border-color: aquamarine; */
    justify-content:center   ;    
    /* width: 50vw; */
    /* background-color: red; */
    /* transform: translateY(10deg); */
    transition: 0;
}
.navbar-nav:nth-child(2){
    /* background-color: green; */
    /* margin-top:-11px; */
}
.navbar-nav1{
    /* border-style: solid;
    border-color: aquamarine; */
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
}

.myNav-item{
    /* margin-left: 90px; */
    align-self: center;
    padding-left: 2px;
    /* border-style: solid;
    border-color: aqua; */
    justify-self: flex-start;
    /* margin-left: 32px; */
    padding-left:10px ;
     margin-left: 27px;
     height: 70px;
     align-items: center;
     justify-content: flex-start;
     display: flex;
     width: 167px;
     
    
}

a{
    text-decoration:none
}
.myNav-item:hover{
    /* border-bottom: 2px solid #000;
    transition: all 10s ease-in-out; */

    /* transform: translate(0%, 0); */
    /* border-bottom: solid 5px #FC5185;
  transition: border-width 0.6s linear; */
    
}

.myNav-links::after {
    /* transition: all ease-in-out 0.3s;
    background: none repeat scroll 0 0 #ffffff;
    content: "";
    display: block;
    height: 2px;
    width: 0px;
   
     margin-top: 2px;

    background-color: black;
    margin-top: 25px;  */

}
.myNav-links:hover:after {
    /* width: 100%;
    border-style: 1px solid;
    background-color: black;
    margin-top: 25px; */
    /* transition: all ease-in-out 2s; */

}

.myNav-item:nth-child(1){
    /* border-color: red;
    border-style: solid; */
    width: 120px;
}

.myNav-links {
    font-family: Asap;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    
    color: #222222;
    text-decoration: none;
    height: auto;
    /* height: 70px; */
    /* height: 100px; */
    /* border-style: solid; */
    position: absolute;
    cursor: pointer;
    /* align-items: center; */
    /* display: flex;
    flex-direction: column; */
    /* margin-top: 50px; */
    /* display: flex; */
     /* width: 175px; */
     /* align-self: center; */

  }
  
  .myNav-links:hover {
    /* border-bottom: 2px solid #000; */
    transition: all 0s ease-in-out;
    color: #222222;
    /* border-style: solid */
    
    ;
    
  }

.watchDemo-button{
    height: 46px;
    width: 120px;
    background-color: #3a86ff;
    color:#fff;
    font-family: Asap;
    font-size: 14px;
    border:1px solid #3a86ff;
    border-radius: 4px;

    
}
.navbar-brand{
    /* border-style: solid;
    border-color: orchid; */
    margin-left: -5px;
    cursor: pointer;
}

.signUp-Button{
  height: 46px;
  width: 120px;
  background-color: transparent;
  color:#3a86ff;
  font-family: Asap;
  font-size: 14px;
  border:1px solid #3a86ff;
  border-radius: 4px;
  /* -left: 30px; */
}

.nav-link{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 18px;

color: #222222;
/* gap: 20px; */
}

.nav-link:hover{
    color:#222222;
    /* border-style: solid;
    border-color: red;
    border-style: solid; */
    
}

 .caret-anime1{
    transform:rotate(-180deg);
    transition:all 0.09s ease-in-out; /*for smoothness*/
    height: 7px;
    align-self: center;
    cursor: pointer;
    margin-left: 5px;
    margin-top: -5px;
}
.caret-anime{
    /* width: 20px; */
    height: 7px;
    align-self: center;
    cursor: pointer;
    margin-left: 5px; 
     /* margin-top: 5px; */
     
   ;
}
.caret-anime:hover{
    /* transform:rotate(-90deg);
        transition:all 0.3s ease-in-out; for smoothness */
}


.one-megaMenu{
    
    /* display: flex; */
    /* flex-direction: row; */
    
    height: 100%;
    min-height: 200px;
    /* min-height: 200px; */
    left: 0;
    
    position: relative;
    top: 135px;
    /* align-self: flex-end; */
    left: -12px;
    /* width: 100%; */
    /* z-index: 3300; */
    /* justify-content: center; */
    /* border-color: red;
    border-style: solid; */
    /* justify-self: center; */
    align-items: flex-start;
justify-content: start;
  }

  .navbar-toggler span {
    display: block;
    background-color: #4f4f4f;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
}


/* top line needs a little padding */
.navbar-toggler span:nth-child(1) {
    margin-top: 0.3em;
}

/**
 * Animate collapse into X.
 */

/* top line rotates 45 degrees clockwise and moves up and in a bit to close the center of the X in the center of the button */
.navbar-toggler:not(.collapsed) span:nth-child(1) {
    transform: translate(15%, -33%) rotate(45deg);
}
/* center line goes transparent */
.navbar-toggler:not(.collapsed) span:nth-child(2) {
    opacity: 0;
}
/* bottom line rotates 45 degrees counter clockwise, in, and down a bit to close the center of the X in the center of the button  */
.navbar-toggler:not(.collapsed) span:nth-child(3) {
    transform: translate(15%, 33%) rotate(-45deg) ;
}


/**
 * Animate collapse open into hamburger menu
 */

/* top line moves back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(1) {
    transform: translate(0%, 0%) rotate(0deg) ;
}
/* middle line goes back to regular color and opacity */
.navbar-toggler span:nth-child(2) {
    opacity: 1;
}
/* bottom line goes back to initial position and rotates back to 0 degrees */
.navbar-toggler span:nth-child(3) {
    transform: translate(0%, 0%) rotate(0deg) ;
}

  .mySub-menuContainer{

    /* height:180px; */
    

     margin-top: 0px;
     width:auto;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 5px ; */
    background-color: red;
    height: auto;
    background-color: #fff;
    border-top: 1px solid #b9b9b9;
    -webkit-box-shadow: 0 16px 24px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 16px 24px 0 rgb(0 0 0 / 8%);
    align-items: center;
    /* left: 100px */
    /* padding-left: 18px */
    
    
    
    /* margin-left: 0rem; */
    
    /* position: absolute; */
    
    /* margin-left: 13rem; */
    /* min-height: 180px; */
    /* height: auto; */
    /* max-height: 240px; */
    
    
    /* justify-self: center; */
    /* max-width: 2455px; */
    /* min-width: 166px;    */
    /* width: 100% */
    
    /* width:1100px */
    ;
    /* border-style: solid;
    border-color: #3a86ff; */
padding-bottom:13px

}


    @media screen and (max-width:1390px) {

        .mySub-menuContainer{
            /* border-style: solid;
    border-color: red; */
    /* width: 920px; */
    /* grid-template-columns: [col]38% [col]39% [col] 10px; */
        }
    }

    @media screen and (min-width:1520px) and (max-width:1800px)  {
        .mySub-menuContainer{
            /* border-color: purple;
            border-style: solid; */
            /* width: 1230px;
    grid-template-columns: [col]41% [col]41% [col] 10px; */
        }
        
    }


    @media (min-width: 320px) and (max-width:1190px){
  .navbar .container {
      /* margin-right: 20px;
      margin-left: 20px; */
      position: absolute
  }
  .navbar-toggler{
      border-style: 1px solid black;
      margin-right:15px;
  }
  .navbar-nav{
      background: white;
      display: flex;
      /* align-self: flex-start; */
      position: inherit;
      /* transform: scaleY(180deg); */
      height: 100vh;
      align-items: flex-start;
      justify-content: flex-start;
      
  }
  .navbar-brand{
      margin-left: 15px;
  }
  .nav-item{
      /* border:1px solid black; */
      height: fit-content;
      width: 100%;
  }
  .myNav-links{
      margin-top: 0;
  }
  .dropdown-toggle{
      height: fit-content;
  }
  .li .dropdown-item{
      height: fit-content;
      
  }
   .dropdown-item{
      height: fit-content;
      width: fit-content;
      margin-left: 15px;
      font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 18px;

color: #000000;
padding-bottom: 20px;
  }
  .dropdownListClass{
      height: fit-content;
  }
  .dropdown-menu.show{
      background: #F4F4F4;
      padding-bottom: 0px;
      height: fit-content;
      /* width: auto; */
      /* position: inherit; */
      width: 130%;
      display: flex;
      flex-flow: column;
      margin-left: -30px;
  }
 
.dropdown-toggle::after{
    /* transform: rotate(180deg);
  transition: all ease-out 0.4s; */
  display: none;
}
.dropdown-toggle[aria-expanded="true"] .caret {
    transform: rotate(-180deg);
    transition: all ease-out 0.2s;
    border: none;
  }
  .dropdown-toggle[aria-expanded="true"] :focus{
      outline: none;
  } 
.dropdown-toggle[aria-expanded="false"] .caret {
    transform: rotate(0deg);
    transition: all ease-out 0.2s;
  }

.nav-link{
    font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;

color: #000000;
justify-content: space-between;
display: flex;
/* border-style: solid; */
/* padding-left: 35px; */
}
.nav-link:focus{
    outline: none;
    color: black;
    
}
.dropdownListClass:focus{
outline: none;
color: black;
}
.dropdown-item:focus{
    background: transparent;
 color: black;
}
.dropdown-item:hover{
    background: transparent;
    color: black;
}
.dropdownListClass:hover{
    background: transparent;
    color: black;
}
.nav-item{
    /* border-style: solid; */
    /* border-color: red; */
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    /* padding-bottom: 20px; */
    padding-top: 30px;
}
}