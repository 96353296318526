/* <-!useCase section-> */

#UseCase{
    height: auto;
    /* min-height: 100px; */
    background:#FFFF no-repeat scroll left center;
    background-size: cover;
    /* background-image: url("./../images/use\ cases\ banner-09-09-09.png"); */
    display:flex;
    /* align-items: center; */
    /* justify-content:center; */
    overflow:hidden;
    /* margin-bottom: 2rem; */
    /* border-style: solid; */
    padding-bottom: 8rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-top: 4rem;
}

.useCaseBgImage{
    height: 650px;
    width: auto;
    align-self: flex-start;
    margin-top: 120px; 
    position: absolute;
    /* border-style: solid; */

}

#UseCase p{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 23px;
/* identical to box height */


color: #222222;
margin-top: 20px;

}

#UseCase h2{
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 34px;
/* identical to box height */


color: #222222;
margin-top: 55px;
/* border-style: solid; */
margin-left: 40px;
}

.chartImage{
    height: 330px;
    width: auto;
    margin-top: 110px;
    margin-left: 30px;
    position: relative;
}

.useText-container{
    /* height: auto; */
    width:auto;
    
    /* margin-top: 13.5rem; */
    /* margin-left: -1.5rem; */
    /* align-self: flex-end; */
    /* position: relative; */
    
    margin-top: 50px;
    /* margin-bottom: 10rem; */
    /* border-style: solid; */
    margin-left: 30px;
    
    
  }
  .useCase-Button{
    font-size: 15px;
    width: 157px;  height: 46px;
  
    margin-top: 2rem;
    margin-left: 10px;
    background: #3A86FF;
    ;
    font-family: Asap;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 65px;
  }

  .whiteArrowImage{
    height: 10px;
    margin-left: 4px;
  }
  .innerUse-text{
    height:auto;
    width: 520px;
    margin-top: 15px;
    
    /* margin-top: 30px; */
    /* border-style: solid; */
    /* margin-left: 48px; */
    /* border-color: lightcoral;
    border-style: solid; */
    /* border-style: solid;
        border-color:#fff */
    
  }
  
  .big-useText{
    font-size: 18px;
    color: #202222;    ;
    font-weight: 700;
    height:auto;
    width: 280px;
    display: grid;
    grid-template-columns: 8% auto ;
    ;
    grid-column-gap: 35px;
    
    margin-left: 10px;
    /* border-style: solid;
    border-color: limegreen; */
    /* margin-left: 10px; */
    
  }
  
 
  .round-container2{
    background-color:  transparent;
    /* border-radius: 50%; */
    height:auto;
    width: auto;
    margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-style: solid; */
  
  
  
    
  }
  .circleClass{
position: absolute;
height: 80px;
  }

  .ActualIconClass{
position: relative;
margin-top: -10px;
height: 25px;

  }
  .round-text{
    color: #FFFFFF;
    font-size: 15px;
  }
  
  

  .small-UseText{
    height: auto;
    width:450px;
    font-size: 16px;
    color: #1f1f1f;;
    /* margin-top: -40px; */
    font-weight: 300;
    margin-left: 68px;
    font-family: Asap ;
    line-height: 24px;
    margin-top: -5px;
    
    
  }

  /* <-!Developer Section-> */

  #Developers{
    /* height: 680px; */
    height: auto;
    /* min-height: 100px; */
    
background: rgba(58, 134, 255, 0.03);
    background-size: cover;
    display:flex;
    /* align-items: center; */
    /* justify-content:center; */
    overflow:hidden;
    /* border-style: solid;
    border-color: purple; */
    /* margin-bottom: 10rem; */
    padding-bottom: 5rem;
  }

  #Developers p{
    font-family: Asap;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    
    color: #222222;
  }
  

   #Developers .card{
border-style: solid;
border-color: blue;
margin-left: 0;
/* justify-content: center; */
/* margin-left: 20px; */
width: 540px;
height: auto;

  }

  #Developers .row{
      
      justify-content: flex-start;
      /* gap: 280px; */
      margin-top: 50px;
      /* border-style: solid; */


  }
.forDeveloperImage{
  height: 400px;
  margin-top: 20px;
  margin-left: 100px;
}
  
.developerCardImage
{
    height: 200px;
    width: auto;
}

  #Developers .card-body{
      justify-content: center;
      text-align: center;
  }

  #Developers h4{
    font-family: Asap;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    
    color: #222222;
    border-color: chartreuse;
    border-style: solid;
    width: 520px;
    height: 30px;
  }

  #Developers text{
    font-family: Asap;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    
    color: #707070;
  }


  /* <!-getStarted section-> */


  #GetStarted{
    height: auto;
    /* min-height: 100px; */
    background:#FFFF no-repeat scroll left center;
    background-size: cover;
    display:flex;
    /* align-items: center; */
    /* justify-content:center; */
    overflow:hidden;
    flex-direction: column;
    padding-bottom: 5rem;
  }

  .getStartedBg{
      /* align-self: flex-end;
      justify-self: flex-end; */
      
  }

  #GetStarted p{
    font-family: Asap;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    
    color: #222222;
    margin-top: 10px;
    margin-left: 0px;
    width: 420px;
    color: #000000;
    margin-bottom: -5px;

  }

  #GetStarted h2{
    font-family: Asap;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    
    color: #141414;
    margin-top: 0px;
    /* border-style: solid; */
    height:40px;
    /* margin-left: 20px; */
  }
.GetSTartedMessageInput{
  height: auto;
    min-height: 100px;
    /* padding-top: -50px; */
    align-items: flex-start;
    display: flex;
    width: 87.5%;
    text-align: top;
    border: 1.5px solid #858585;
    margin-left: 20px;
    margin-top: 25px;
    padding-left: 30px;

}
.GetSTartedMessageInput:focus{
  outline-color: #3A86FF
}
  .contact-formContainer{
    height:auto;
    width: 530px;
    
    align-self: flex-start;
    margin-top: 50px;
    /* margin-right: -.4rem; */
    /* margin-left: 100px; */
    
   
    /* border-style: solid; */
    padding-bottom: 1.5rem;
    border: 1px solid #E9E6E6;
    padding-top: 1.5rem;
    margin-left: -9rem;
  
  }
  .contactWeb-formContainer{
    height:auto;
    width: 530px;
    
    align-self: flex-start;
    margin-top: 50px;
    /* margin-right: -.4rem; */
    /* margin-left: 100px; */
    
   
    /* border-style: solid; */
    padding-bottom: 1.5rem;
    border: 1px solid #E9E6E6;
    padding-top: 1.5rem;
    margin-left: -9rem;
    padding-left: 15px;
    padding-right: 15px;
  
  }
 .error{
   /* border-color: brown;
   border-style: solid; */
   height: fit-content;
   color: red;
   width: fit-content;
   position: absolute;
   padding-bottom: 0px;
   margin-left: 20px;
 }
 .mobileError{
   /* border-color: brown;
   border-style: solid; */
   height: fit-content;
   color: red;
   width: fit-content;
   position: absolute;
   padding-bottom: 0px;
   margin-left: 10px;
   font-size: 12px;
 }

  .text-input1{
  height:50px;
    width:460px;
    margin-top: .6rem;
    /* margin-left: 10px; */
    box-sizing: border-box;
    border: 1.5px solid #858585;
    position: relative;
    padding-left: 25px; 
    border-radius: 4px;
    font-family: Asap;
   margin-left: 20px;
   color: #1E1E1E;

  
  
  }
  .text-input1:focus{
    outline-color: #3A86FF;
  }
  .text-input2:focus{
    outline-color: #3A86FF;
  }
  .text-input2{
    height:50px;
    width: 460px;
    margin-top: 1.5rem;
    /* margin-left: 10px; */
    box-sizing: border-box;
    border: 1.5px solid #858585;
    position: relative;
    padding-left: 25px; 
    border-radius: 4px;
    font-family: Asap;
    margin-left: 20px;
    color: #1E1E1E;


  
  }
  .contact-usButton{
    font-size: 15px;
    width: 167px;  height: 46px;
  
    margin-top: 2rem;
    margin-left: 20px;
    background-color: #3A86FF;
    font-family: Asap;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  
  }

  .getstarted-Original{
      height: 300px;
      /* width: 200px; */
      /* border-color: chartreuse;
      border-style: solid; */
      margin-top: 150px;
      margin-left: -30px;
      /* margin-bottom: 6rem; */
      position: relative;
    }

  .getStartedBg{
      position: absolute;
      align-self: flex-end;
      height: 560px;
      /* width: 30%; */
      /* margin-bottom: 50px; */
      margin-top: 80px;
  }

  .privacy-image{
    height: 30px;
  }
  .priv-div{
    /* border-color: purple;
    border-style: solid; */
    height:35px;
    /* margin:10px */
    margin-left: 20px;
    margin-top:20px;
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 18px;
display: flex;
width: 255px;

color: #000000;
;
justify-content:space-between;
align-items: center;
  }

  .resultClass{
    background-color: green;
    height: auto;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    font-size: 14px;
    font-family: Asap;
    color: ivory;
    /* margin-left: 20px; */
    padding-left: 10px;
    font-weight: bold;
  }

  @media screen and (min-width:1520px) and (max-width:1800px) {

      .getStartedBg{
          height: 700px;
          margin-top: 70px;
      }
      .circleClass{
        height: 100px;
      }
      .ActualIconClass{
        height: 29px;
      }
      .getstarted-Original{
          /* margin-bottom: 10rem; */
          height:370px;
          margin-left: -20px;
      }
      .useCaseBgImage{
          height: 760px;
          /* aspect-ratio: 1/1; */
          /* margin-top: 13rem; */
          /* margin-bottom: 10rem; */
          margin-top: 120px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
      }
      .chartImage{
          height: 400px;

          margin-left: 40px;
          margin-top: 114px;
          /* margin-bottom: 13rem; */
      }
      /* .useText-container{
          margin-bottom: 14.5rem;
      } */
      #UseCase{
          /* height: 950px; */
          /* border-style: solid;
          border-color: brown; */
          padding-bottom: 10rem;
          /* border-style: solid; */
      }
      #UseCase p{
        font-size: 26px;
      }
      #UseCase h2{
        font-size: 37px;
        margin-top: 50px;
        margin-left: 90px;

      }
      .big-useText{
        font-size: 21.2px;
        /* border-style: solid; */
        width: 330px;
        gap: 35px;
      }
      .small-UseText{
        font-size: 19px;
        line-height: 30px;
        /* border-style: solid; */
        width: 540px;
        margin-top: -2px;
        margin-left: 71px;
      }
      .innerUse-text{
        margin-top: 15px;
      }
      .useText-container{
        margin-bottom: 0;
        margin-top: 50px;
        margin-left: 80px;
      }
      #useCase h2{

      }
      #Developers p{
        font-size: 26px;
        
      }
      #Developers h2{
        font-size: 37px;
        /* margin-top: 20px; */
      }
      #GetStarted p{
        font-size: 16px;
        margin-bottom: -20px;

      }
      #GetStarted h2{
        font-size: 37px;
        margin-top: 20px;
      }
      .contactWeb-formContainer{
        margin-top: 49px;
        margin-left: 30px;
        width:560px
      }
      .forDeveloperImage{
        height: 500px;
        margin-top: 20px;
        margin-left: 140px;
      }
      #Developers{
        padding-bottom: 7rem;
      }
  }

  @media screen and (min-width:1430px) and (max-width:1520px) {
    .getStartedBg{
      height: 660px;
      /* height: auto; */
    }

    .forDeveloperImage{
      height: 450px;
      margin-top: 0px;
      margin-left: 110px;
    }
    
    #UseCase h2{
      margin-left: 100px;
    }
    .useText-container{
      margin-left: 90px;
    }
  }


  @media (min-device-width: 320px) and (max-device-width:500px){ 
#UseCase{
  /* border-style: solid; */
  justify-content: center;
  padding-top: 0;
  padding-bottom: 2rem;
}
.GetSTartedMessageInput{
  width: 96%;
  margin-left: 0;
}
.useCase-Button{
  width: 100%;
  margin-left: 0;
}
#UseCase .container{
  /* border-style: solid; */
  border-color: red;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  ;
}
.resultClass{
  width: 95%;
  margin-left:0;
  padding-left: 4px;
  padding-right: 10px;
}
#UseCase .row{
  width: auto;
  padding:0;
  margin-left:15px;
  margin-right: 15px;
  /* border-style: solid; */
  display: flex;
  flex-flow: column-reverse;
}
#UseCase h2{
  /* border-style: solid; */
  margin-left: 0px;
  font-size: calc(3vw + 1.3vh + 1.84vmin);
}
.useText-container{
  /* border-style: solid; */
  margin: 0px;
}
.innerUse-text{
  /* border-style: solid; */
  width: auto ;
  padding-left:12px 
  ;
  padding-right: 4px;
}
.big-useText{
  /* border-style: solid; */
  width: auto;
  margin: 0;
  gap: 8.3%;
}
.round-container2{
  margin-top:22px
}
.small-UseText{
  /* border-style: solid; */
  margin-left:  clamp(46px, 17%, 66px);
  width: auto;
  margin-top: -10px;
   font-size: 14px;;
   flex-basis: content;
   position: relative;
}
.chartImage{
  margin-top:  4rem;
    height: 100%;
    /* aspect-ratio: 1; */
    
     width: 90%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: block;
    /* align-self: flex-start; */
    /* margin-left: -8%; */
    /* border-color: orange;
    border-style: solid; */
    margin-left: 0;
}

#UseCase .col:nth-child(1){
  /* border-style: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

#Developers{
  /* border-style: solid; */
  padding-bottom: 2rem;
}
#Developers .row{
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-flow: column;
}
.forDeveloperImage{
  margin: 0;
  height: 100%;
  margin-top: 50px;
}
#GetStarted{
  /* border-style: solid; */
}
#GetStarted .row{
  margin-left: 15px;
  margin-right: 15px;
  /* border-style: solid; */
  /* justify-content: center; */
  display: flex;
}
#GetStarted .col:nth-child(2){
  /* padding-right: 0px;
  padding-left: 0px; */
  /* border-style: solid; */
  display: flex;
  width: 100%;
  margin-left: 0;
  flex-direction: column;
}
#GetStarted .col:nth-child(1){
  /* padding-right: 0px;
  padding-left: 0px; */
  /* border-style: solid; */
  display: none;
  width: 100%;
  margin-left: 0;
  flex-direction: column;
}
.getstarted-Original{
  display: none;
}
.contact-formContainer{
  width: 100%;
  align-self: center;
  align-items: center;
  margin:0;
  padding-left: 13px;
  margin-top: 50px;
  /* margin-left: 1rem; */
}
.contact-formContainerMobileFirst{
  width: 100%;
  align-self: center;
  align-items: center;
  margin:0;
  padding-left: 13px;
  margin-top: 50px;
  /* margin-left: 1rem; */
  border: 1px solid #E9E6E6;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  
}

#GetStarted h2{
  width: 100%;
  font-size:calc(2vw + 2vh + 2vmin);
  margin: 0;

}
#GetStarted p{
  width: 100%;
  margin-left: 0px;
  /* border-style: solid; */
  font-size: 14px;
  margin-bottom: 0px;
}
.text-input1{
  width: 96%;
  margin:0
}
.text-input2{
  width: 96%;
  margin-left:0
}
.priv-div{
  /* width: 100%; */
  margin-left: 0;
}
.contact-usButton{
  width: 96%;
  margin-left:0
}
  }


  @media only screen and (width:411px)  {
  .big-useText{
    /* border-style: solid; */
    gap:18px
  }
  }
  @media only screen and (width:320px)  {
  .big-useText{
    /* border-style: solid; */
    gap:28px
  }
  }
  @media screen and (min-device-width:361px) and (max-device-width:480px) {
  .big-useText{
    /* border-style: solid; */
    /* gap:18px */
  }
  }
  @media screen and (min-device-width:320px) and (max-device-width:360px) {
  .big-useText{
    /* border-style: solid; */
    /* gap:28px */
  }
  }
  


  @media screen and (min-width:1800px) {
    .contactWeb-formContainer{
      margin-left: 110px;
    
    }    
  }