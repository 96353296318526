#ContactUsPage1{
    height: 100%;
    
    background: white;     background-size:cover;
    background-image:url('../../../../images/Group\ 210.png');
    background-position: 0 0, 0 0;
background-repeat: no-repeat, no-repeat;
    display:flex;
    overflow:hidden;
    align-items: center; 
    /* justify-content:center;
    /* flex-direction: column; */
    /* z-index: 1300; */
    /* border-color: red;
    border-style: solid; */
    margin-top: 100px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* padding-bottom: 20rem; */
}


#ContactMobilepage{
    display: none;
}

.contact-text{
    height: auto;
    width: auto;
    margin-top: 80px;
    /* border-style: solid; */
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.contact-text1{
    height: auto;
    width: auto;
    margin-top: 80px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* border-style: solid; */
}

.contact-BigText{
    
font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 55px;
box-sizing: border-box;
    -webkit-box-sizing: border-box;
/* identical to box height */


color: #141414;
/* border-style: solid; */
}
.resultClass1{
    background-color: green;
    height: auto;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    font-size: 14px;
    font-family: Asap;
    color: ivory;
    /* margin-left: 20px; */
    padding-left: 10px;
    font-weight: bold;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}



.contact-SmallText{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 23px;

color: #1E1E1E;
width: 400px;
/* border-style: solid; */
margin-top: 10px;
box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.contactUs-formContainer{
    height:auto;
    width: auto;
    
    align-self: flex-start;
    margin-top: 50px;
    /* margin-right: -.4rem; */
    /* margin-left: 100px; */
    margin-left: -40px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
   
    /* border-style: solid; */
    /* padding-bottom: 1.5rem; */
    /* border: 1px solid #E9E6E6; */
    /* padding-top: 1.5rem; */
    /* margin-left: -9rem; */
    background: white;
    padding:50px;
    border-radius: 4px;
    background: #FFFFFF;
box-shadow: 0px 6px 20px rgba(156, 156, 156, 0.25);
border-radius: 4px;
}

h2{
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
/* identical to box height */


color: #141414;
box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.firstRowInput{
    height: 60px;
    width: auto;
    background: transparent;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    /* border-style: solid; */
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.firstRowInput1{
    height: 60px;
    width: auto;
    background: transparent;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    /* border-style: solid; */
}

.input2{
    height: auto;
    /* width: auto; */
    /* background: red; */
    width: 47%;
}
.input1{
    height: auto;
    /* width: auto; */
    /* background: yellow; */
    width: 47%;
}
.contactUs-text-input1{
    height: 50px;
    border-radius: 4px;
    background: transparent;
    padding-left: 20px;
    font-size: 12px;
    
    border: 1.5px solid #858585;
    width: 100%;


}
.contactUs-text-input2{
    height: 50px;
    border-radius: 4px;
    background: transparent;
    padding-left: 20px;
    font-size: 12px;
    width: 100%;
    border: 1.5px solid #858585;


}
.contactUs-text-input2:focus{
    outline-color: #3A86FF;

}
.contactUs-text-input1:focus{
    outline-color: #3A86FF;

}

.secondRowInput{
    height: 100px;
    width: auto;
    background: yellow;
    margin-top: 20px;
    /* display: flex;
    align-items: flex-start; */
}

.messageInput{
    height: auto;
    min-height: 100px;
    padding-top: -50px;
    align-items: flex-start;
    display: flex;
    width: 100%;
    text-align: top;
    border: 1.5px solid #858585;
    padding-left: 20px;
    padding-top: 10px;


}



.messageInput:focus{
    outline-color: #3A86FF;
}

.contactError{
    /* position: initial; */
    display:flex;
    flex-basis: initial;
    margin-top: 3px;
    height: fit-content;
    /* border-style: solid; */
    width: fit-content;
    /* margin:10% */
    /* justify-content: space-between; */
    font-size: 12px;
    color: red;
    padding-left: 10px;


}
.priv-divContact{
    height:35px;
    /* margin:10px */
    /* margin-left: 20px; */
    margin-top:20px;
    font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;

color: #000000;
display: flex;
width: 232px;

/* color: #000000; */
/* border-style: solid */
;
justify-content:space-between;
align-items: center;
}
.contactUsPage-Button{
    font-size: 15px;
    width: 100%;  height: 46px;
  
    margin-top: 20px;
    /* margin-left: 20px; */
    background-color: #3A86FF;
    font-family: Asap;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}



/* 
<!-----Map section-----------!> */


#Map{
    height: auto;
    background: #FBFBFB;     background-size:cover;
    /* background-image:url('../../../../images/Group\ 210.png'); */
    background-position: 0 0, 0 0;
background-repeat: no-repeat, no-repeat;
    display:flex;
    overflow:hidden;
    align-items: center; 
}

.Map-text{
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 36px;
/* line-height: 55px; */
}

.icon-Div{
    /* position: absolute; */
width: 400px;
height: 100px;
/* left: 159px; */
/* top: 995px; */
margin-top: 30px;

background: #FFFFFF;
box-shadow: 0px 6px 20px rgba(180, 180, 180, 0.25);
border-radius: 4px;
display: flex;
align-items: center;
justify-content: flex-start;
/* justify-content: space-around; */
padding-left: 10px;
box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.icon-Div1{
    /* position: absolute; */
width: 400px;
height: 100px;
/* left: 159px; */
/* top: 995px; */
margin-top: 40px;

background: #FFFFFF;
box-shadow: 0px 6px 20px rgba(180, 180, 180, 0.25);
border-radius: 4px;
display: flex;
align-items: center;
/* justify-content: space-around; */
padding-left: 10px;
box-sizing: border-box;
justify-content: flex-start;
        -webkit-box-sizing: border-box;
}

.imageContainer{
    height: 50px;
    aspect-ratio: 1;
     background: #0028A9;
    ;
    /* float: left; */
    /* border-style: solid; */
    align-items: center;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-left: 15px;
    border-radius: 50%;
}
.imageContainer1{
    height: 50px;
    aspect-ratio: 1;
    background: #F5B808;
    /* float: left; */
    /* border-style: solid; */
    align-items: center;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    margin-left: 15px;
    border-radius: 50%;
}

p{
     font-family: Asap;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */
    /* border-style: solid; */
    height: fit-content;
    margin-left: 20px;
    /* float: right; */
    margin-top: 15px;
    
    
    color: #141414;;
}

.circleImage{
    height:auto;
    /* aspect-ratio: 1; */
    position: absolute;
    margin-top: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    align-items: center;

}
.phoneImage{
    position: relative;
    height: 18px;
    /* border-style: solid; */
    align-self: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* margin-top: 3px; */
}

.googleMap{
    width: fit-content;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
@media screen and (min-width:1800px) {
    #ContactUsPage1{
        height: 850px;
        padding: 0px;
    }
    
    
}

@media screen and (min-width:1520px) and (max-width:1800px) {
    .contact-BigText{
        font-size: 48px;
    }
    .contact-SmallText{
        font-size: 19px;
        width: 430px;
        line-height: 29px;
    }
    h2{
        font-size: 30px;
    }
    
    .contactUs-text-input1{
        height: 60px;
    
    }
    .contactUs-text-input2{
        height: 60px;
    
    }
    .priv-divContact{
        height: 55px;
        /* border-style: solid; */
        font-size: 16px;
        width: 255px;
    }
    .firstRowInput{
        margin-top: 40px;
    }
    .firstRowInput1{
        margin-top: 30px;
    }
    .contactUsPage-Button{
        height: 48px;
        font-size: 18px;
    }
    .secondRowInput{
        margin-top: 30px;
    }
    .googleMap{
        height: 600px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    }
}



@media screen and (min-width:320px) and (max-width:500px) {

    #ContactMobilepage{
background-image:url('../../../../images/contactUsMobileBanner.png');
background-size: contain;
background-position: 0 0, 0 0;
background-repeat: no-repeat, no-repeat;
    display:flex;
    overflow:hidden;
    align-items: center; 
height: auto;
min-height: 150px;
/* border-color: rebeccapurple;
border-style: solid; */
position: relative;
/* margin-top: 10%; */
display: flex;
justify-content: center;
align-items: center;
z-index: 0;
/* width: 100%; */
margin-top: 70px;

    }
    #ContactMobilepage .row{
        /* border-color: red;
        border-style: solid; */
        align-items: center;
        display: flex;
        margin-left: 15px;
        margin-right: 15px;
        align-self: center;
        margin-top: 10%;
    }
    #ContactMobilepage h1{
        /* border-style: solid; */
        /* text-align: center; */
        font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 23px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
    }
       #ContactUsPage1{
        /* border-style: solid; */
        /* display: flex; */
        /* flex-direction: column-reverse; */
        margin: 0;
        background-image: none;
        /* border-style: solid; */
        height: fit-content;
        padding-bottom: 2rem;
    }
    #ContactUsPage1 p{
        /* border-style: solid; */
        margin-left: 0;
        font-size: 14px;
        line-height: 19px;
        font-weight: normal;
        margin-bottom: 0px;
        padding-right: 10px;
    }
   
    #ContactUsPage1 .col:nth-child(1){
        /* border-style:solid; */
        display: none;
    }
    #ContactUsPage1 .container{
        /* border-style: solid; */
    }
    #ContactUsPage1 .row{
        margin-left: 15px;
        margin-right: 15px;
        /* border-style: solid; */
        flex-flow: column;
        display: flex;
        padding-top: 0;
    }
    .contactUs-formContainer{
        margin: 0;
        /* margin-top: 100px; */
        /* border-style: solid;
        border-color: red; */
        padding:20px 20px 20px 20px ;
        width: 100%;
        margin-top: 39px;
    }
    .contact-text{
        display: none;
    }
    .firstRowInput{
        /* border-color: turquoise
        ;
        border-style: solid; */
        height: auto;
        display: flex;
        flex-flow: column;
    }
    .firstRowInput1{
        /* border-color: turquoise
        ; */
        /* border-style: solid; */
        height: auto;
        display: flex;
        flex-flow: column;
        margin-top: 10px;
    }
    .input2{
        margin-top: 10px;
    }
    .input1,.input2{
        width: 100%;
    }
    
    .googleMap{
        display: none;
    }
    #Map{
        padding-bottom: 1rem;
    }
    #Map .row{
        /* border-style: solid;
        border-color: blue; */
        margin-left: 15px;
        margin-right: 15px;
    }
    .contact-text1{
        /* border-color: red;
        border-style: solid; */
        width: 100%;
        margin-top: 20px;
        /* font-size: 24px; */

    }
    .Map-text{
        font-size: calc(3vw + 1.3vh + 1.84vmin);
        ;
    }
    .icon-Div{
        width: 100%;
        justify-content: flex-start;
        padding:0;
        display: flex;
        /* align-items: flex-start; */
        height: fit-content;
        background: transparent;
        box-shadow: none;
        margin-top: 10px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .icon-Div1{
        width: 100%;
        padding:0;
        height: fit-content;
        margin-top: 0px;
        background: transparent;
        box-shadow: none;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .circleImage{
        height: 80px;
        ;
        margin-left: 0;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }
    .phoneImage{
        height: 12px;
        /* margin-top: 8px; */
    }
    .imageContainer{
        margin-left: 0px;
        /* border-style: solid; */
        /* height: fit-content; */
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        align-items: center;
        /* margin-top: 10px; */
        /* height: 80px; */
        height: 40px;
    }
    .imageContainer1{
        margin-left: 0px;
        /* border-style: solid; */
        /* height: fit-content; */
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        align-items: center;
        /* margin-top: 10px; */
        height: 40px;
    }
    #Map p{
        font-size: 18px;
        margin-left: 18px;
        /* margin-top: 20px; */
    }
    .text-Contactinput1{
        height:50px;
    width:95%;
    margin-top: 1.2rem;
    /* margin-left: 10px; */
    box-sizing: border-box;
    border: 1.5px solid #858585;
    position: relative;
    padding-left: 25px; 
    border-radius: 4px;
    font-family: Asap;
   /* margin-left: 20px; */
   color: #1E1E1E;
    }
     .text-Contactinput1:focus{
        outline-color: #3A86FF
     }
    .contactError{
        height: 0px;
        font-size: 16px;
        margin-left: 10px;
    }
    .messageInput{
        margin-top: 25px;
        width: 95%;
    }
    .resultClass1{
        width: 96%;
        margin-left: 0;
    }
    
}