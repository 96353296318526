* {
    box-sizing: border-box;
    margin: 0;
  }
  
  :root {
    --heights: 90vh;
    --widths: 70%;
  }
  
  .slider-container {
    height: 640px;
    width: 250px;
    position: relative;
    margin-left: 220px;
    overflow: hidden;
    margin-top: 74px;
    /* border-style: solid; */
    /* justify-content: center; *
    /* background-color: brown; */
  }
  
  .active {
    display: inline-block;
  }
  .arrows{
    /* border-style: solid;
    border-color: red;
    width: 100%; */
  }
  .inactive {
    display: none;
  }
  
  .slides {
    height: 540px;
    width: 250px; 
    position: relative;
    /* border-style: solid; */
    /* margin-top: 20px; */
    /* margin-left: 10px; */
    margin-top: 10px;

  }
  
  .slide-image {
    width: 100%;
    height: 100%;
    position: relative;
    /* object-fit: cover; */
    /* border-style: solid; */
    /* border-color: aqua; */
    /* margin: 0px; */
    /* border-style: solid; */
  }
  
  .slide-title,
  .slide-text {
    width: 100%;
    height: 100%;
    color: black;
    font-size: 50px;
    position: absolute;
    text-align: center;
    top: 40%;
    z-index: 10;
  }
  
  .slide-text {
    top: 65%;
    font-size: 2rem;
  }
  
  .arrows {
    /* cursor: pointer; */
    /* z-index: 100; */
    /* position: absolute; */
    position: inherit;
    /* top: 5%; */
    width: auto;
    /* padding: 0rem; */
    margin-top: -4rem;
    font-size: 40px; 
     font-weight: bold;
    /* border-radius: 0px 5px 5px 0px; */
    /* border-style: solid; */
    height:10%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    

    align-items: center;
    /* height: 40px; */
  }
  
  .prev:hover,
  .next:hover {
    /* color:#3a86ff; */
    /* background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in; */
  }
  
  .next {
    /* right: 10px; */
    border-radius: 50px ;
    height:35px;
    margin-right: 70px;
     background: #F2F2F2;
    ;
    /* height: 30px; */
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin-top: -15px;
  }
  .prev {
    /* left: 5px; */
    border-radius: 50px ;
    height:35px;
    margin-left: 70px;
    background: #F2F2F2;
    /* height: 30px; */
    width: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin-top: -15px;
    /* pointer-events: visiblePainted; */
    


  }
  .prev-image{
    height: 10px;
    transform: rotate(180deg);
    cursor: pointer;

  }
  .next-image{
    height: 10px;
    transform: rotate(180deg);
    cursor: pointer;

  }
  
  .all-dots {
    width: 100%;
    height: 3%;
    position: absolute;
    display: flex;
    top: 89.5%;
    justify-content: center;
    z-index: 200;
    align-self: center;
    justify-self: end;
    /* border-style: solid; */
    align-items: flex-start;
    /* float: right; */
    
  }
  
  .dot {
    cursor: pointer;
    height: .6rem;
    width: .6rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
    /* border-style: solid; */
    /* margin-top: 30px; */
    
  }
  
  .active-dot,
  .dot:hover {
    background-color:#3a86ff;
  }
  

  .text-normal{
      font-size: 32px;
      color: black;
  }
  .text-highlight{
      font-size: 52px;
      color: red;
  }
  .container-text{
      height: 200px;
      margin-top: 100px;
  }

  @media screen and (min-width:1520px) and (max-width:1800px){
    .slider-container{
     height: 760px;
     width: 340px;
     /* border-style: solid; */
     margin-left: 280px;
    }
    .slides{
      height: 680px;
      width: 320px;
      /* margin-top: 30px; */
      
    }
    .prev,
  .next {
    top:90%;
    /* border-style: solid; */
    height: 40px;
    width: 40px;
    display: flex;
  }
  .next {
    margin-right: 100px;
    /* border-radius: 5px 0px 0px 5px; */
    margin-top: -18px;
    
  }
  .prev {
    margin-left: 70px;
    /* border-radius: 5px 0px 0px 5px; */
    margin-top: -18px;

  }
  .all-dots {
    
    top: 91.2%;
    /* border-style: solid; */
    width: 300px;
    
  }
  .arrows{
    /* border-style: solid; */
    margin-top: -4rem;
    /* border-style: solid; */

      }
  }

  @media screen and (min-width:1420px) and (max-width:1520px){
    .slider-container{
      height: 670px;
      /* border-style: solid; */
      width: 300px;
      margin-left: 250px;
      margin-top: 85px;
     }
     .slides{
       height: 600px;
       width: 290px;
       margin-top: 0px;
       /* margin-left: 10px; */
     }
     .prev,
   .next {
     top:100%;
     /* border-style: solid; */
     height: 40px;
     width: 40px;
     display: flex;
     
   }
   .next {
    margin-right:80px;
     /* border-radius: 5px 0px 0px 5px; */
     
   }
   .prev {
     margin-left: 80px;
     /* border-radius: 5px 0px 0px 5px; */
   }
   .all-dots {
     
     top: 90%;
     
   }
   .arrows{
     /* border-style: solid; */
     margin-top: -3.9rem;
       }
  }



  @media (min-width: 320px) and (max-width:500px){

     .slider-container{
           margin-left: 0px;
      margin-top: 0;
      /* border-color: chartreuse;
      border-style: solid; */
      height: 600px;
     }
     .slides{
       /* height: 600px;
       width: 290px; */
       margin-top: 0px;
       /* margin-left: 10px; */
     }
     .prev,
   .next {
     top:100%;
     /* border-style: solid; */
     height: 40px;
     width: 40px;
     display: flex;
     
   }
   .next {
    margin-right:42px;
     /* border-radius: 5px 0px 0px 5px; */
     
   }
   .prev {
     margin-left: 40px;
     /* border-radius: 5px 0px 0px 5px; */
   }
   .all-dots {
     
     top: 91%;
     
   }
   .arrows{
     /* border-style: solid; */
     margin-top: -3.4rem;
       }
  }


  