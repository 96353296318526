

 #UseCasesPage{
    /* min-height: 800px; */
    /* height: 100vh; */
    /* max-height: 1290px; */
    /* height: 1%; */
    height: 205px;
    width: 100%;
    margin-top: 70px;
    
    background: rgba(71, 71, 71, 0.34);     background-size:100%;
    background-image:url('../../images/use\ cases\ banner-09-09-09.png');
    background-position: 0 0, 0 0;
background-repeat: no-repeat, no-repeat;
    display:flex;
    overflow:hidden;
    align-items: center; 
    /* justify-content:center;
    /* flex-direction: column; */
    /* z-index: 1300; */
}

#UseCasesPage .row{
    /* border-style: solid; */
}


#UseCasesPage .container  {
    /*  */
/* border-style: solid;
border-color: red; */

}
#UseCasesPage h1{
    /* border-color: royalblue;
    border-style: solid; */
    margin-top: 70px;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: Asap;
font-style: normal;
font-weight: bold;
font-size: 36px;
/* line-height: 38px; */

color: #FFFFFF;
/* width: 300px; */
text-align: center;
margin-left: -35px;
}

#UseCasesPage .col{
    /* border-color: brown;
    border-style: solid; */
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    /* display: flex; */
    /* border-color: crimson;
    border-style: solid; */
    padding: 0;
    align-self: center;
}

section,
.section {
    position: relative;
}

 .UseCases-Big{
    font-size: 24px;
    font-weight: bold;
    line-height:46px;
    color:#222222;
    /* padding:1.063rem  0; */
    
    font-family: Montserrat;
    width: 530px;
    /* border-style: solid; */
    /* border-color: orange; */
    height: 50px;
}
 .UseCases-small{
    font-size: 16px;
    color: #707070;
    font-weight: normal;
    font-style: normal;
font-family: Montserrat;
line-height: 25px;
    
    margin-top: 20px;
    /* border-style: solid;
    border-color: crimson; */
    height: 115px;
    width: 510px;
    ;

}



 




/* <!-WhyCeplr section-> */

#Section1{
    height: auto;
    /* min-height: 100px; */
    background:#FFFF no-repeat scroll left center;
    background-size: cover;
    display:flex;
    /* align-items: center; */
    justify-content:center;
    overflow:hidden;
    flex-direction: column;
    /* border-color: lawngreen;
    border-style: solid; */
    padding-bottom: 9rem;
}

#Section1 .col{
    /* border-style: solid; */
    height: inherit;
    align-items: center;
    align-self:center ;
}
#Section1 .col:nth-child(1){
    margin-top: 100px;
}
.mfi-Container{
    width: auto;
    /* margin-top: 102px; */
    /* border-style: solid;
    border-color: black; */
    height: auto;

}

.mfiBigText{
    width: 430px;
    font-size: 24px;
    font-family: Asap;
    font-weight: bold;
    line-height: 35px;
    
    margin-top:15px;

}

.mfiHeading-Ceplr{
    /* position: absolute; */
/* width: 119px;
height: 23px;
left: 117.76px; */
/* top: 1057px; */

/* identical to box height */

font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;

color: #3A86FF;
/* border-style: solid; */
width: fit-content;
padding-left: 10px;
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
background: rgba(58, 134, 255, 0.06);
}

.mfi-smallText1{
    /* max-height: 100px;
    max-width: 560px; */
    /* margin-left: 117px; */
    font-size: 16px;
    font-family: Asap;
    line-height: 25px;
    font-weight: 300;
    margin-top: 10px;
    width:500px;
    /* border: 1px solid; */
    color: #1f1f1f;;
}

.mfi-smallText2{
    /* max-height: 100px; */
    /* max-width: 560px; */
    /* margin-left: 117px; */
    color: #707070;
    font-size: 16px;
    font-family: Montserrat;
    line-height: 25px;
    font-weight: 300;
    margin-top: 30px;
}
.getInTouch-btn{
    background-color: #3a86ff;
    color: #FFF;
    border: 1px solid #3a86ff;
    transition: all .3s ease-out;
    width: 190px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 30px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
 }
 .bluearrow{
    height:11px;
    margin-left:9px;margin-top:-3px;
    /* background: url("../../images/blueArrow.png") no-repeat; */
 }
 .getInTouch-btn:hover >.bluearrow{
    background: url("../../images/whiteArrowAshish.png") ;
    height:11px;
    margin-left:9px;margin-top:-3px;
    /* width: 43px; */
    /* border-color: white;
    border-style: solid; */
    /* border: 1px solid yellow; */
 }
 .getInTouch-btn:hover{
    /* background-color: #3a86ff;
    color: white; */

 }
.mfi-Image{
    /* min-width: 600px; */
    margin-left: -100px;
    /* width: 100%; */
    height: 390px;
    margin-top: 100px;
    /* margin-bottom: 10rem; */
    position: relative;
}


#Section2{
    height: auto;
    /* min-height: 100px; */
    background: #f9f9f9 no-repeat scroll left center;
    background-size: cover;
    display:flex;
    /* align-items: center; */
    justify-content:center;
    overflow:hidden;
    flex-direction: column;
    /* border-color: lawngreen;
    border-style: solid; */
    padding-bottom: 9rem;
}

#Section2 .col{
    /* border-style: solid; */
    height: inherit;
    align-items: center;
    align-self:center ;
}
#Section2 .col:nth-child(2){
    margin-top: 100px;
}
.whyCeplrText-Container{
    width: 510px;
    margin-top: 102px;
    /* border-style: solid;
    border-color: black; */

}
.mfi-Container1{
    width: auto;
    /* margin-top: 102px; */
    /* border-style: solid;
    border-color: black; */
    height: auto;
    /* border-style: solid; */
    margin-left: -60px;

}

.mfiBigText1{
    width: 430px;
    font-size: 24px;
    font-family: Asap;
    font-weight: bold;
    line-height: 35px;
    
    margin-top:15px;

}

.mfiHeading-Ceplr1{
    /* position: absolute; */
/* width: 119px;
height: 23px;
left: 117.76px; */
/* top: 1057px; */

/* identical to box height */

font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;

color: #3A86FF;
/* border-style: solid; */
width: fit-content;
padding-left: 10px;
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
background: rgba(58, 134, 255, 0.06);
}

.mfiHeading-Ceplr2{
    font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;

color: #3A86FF;
/* border-style: solid; */
width: fit-content;
padding-left: 10px;
padding-right: 10px;
padding-top: 10px;
padding-bottom: 10px;
background: rgba(58, 134, 255, 0.06);

}

.mfi-smallText11{
    /* max-height: 100px;
    max-width: 560px; */
    /* margin-left: 117px; */
    font-size: 16px;
    font-family: Asap;
    line-height: 25px;
    font-weight: 300;
    margin-top: 10px;
    width:500px;
    /* border: 1px solid; */
    color: #1f1f1f;;
}

.mfi-smallText2{
    /* max-height: 100px; */
    /* max-width: 560px; */
    /* margin-left: 117px; */
    color: #707070;
    font-size: 16px;
    font-family: Montserrat;
    line-height: 25px;
    font-weight: 300;
    margin-top: 30px;
}
.getInTouch-btn1{
    background-color: #3a86ff;
    color: #FFF;
    border: 1px solid #3a86ff;
    transition: all .3s ease-out;
    width: 190px;
    height: 46px;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    font-family: Asap;
    /* justify-self: stretch; */
    
    position: relative;
    margin-top: 30px;
    /* align-self: flex-star */
    /* margin-bottom: 26rem; */
    border-radius: 4px;
 }
 .bluearrow{
    height:11px;
    margin-left:9px;margin-top:-3px;
    /* background: url("../../images/blueArrow.png") no-repeat; */
 }

 .mfi-Image1{
    /* min-width: 600px; */
    margin-left: -100px;
    /* width: 100%; */
    height: 390px;
    margin-top: 100px;
    /* margin-bottom: 10rem; */
    position: relative;
    margin-left: -2px;
}


#Section3{
    height: auto;
    /* min-height: 100px; */
    background:#FFFF no-repeat scroll left center;
    background-size: cover;
    display:flex;
    /* align-items: center; */
    justify-content:center;
    overflow:hidden;
    flex-direction: column;
    /* border-color: lawngreen;
    border-style: solid; */
    padding-bottom:13rem;
}
#Section3 .col{
    /* border-style: solid; */
    height: inherit;
    align-items: center;
    align-self:center ;
}
#Section3 .col:nth-child(1){
    margin-top: 100px;
}
.whyCeplrText-Container{
    width: 510px;
    margin-top: 182px;
    /* border-style: solid;
    border-color: black; */

}


@media screen and (min-width:1280px) {
   
    .mfiHeading-Ceplr2 {display: none;}
}



@media screen and (min-width:1520px) and (max-width:1800px)  {
    .howDoesBg{
        height: 750px;
    }
    .mfi-Container1{
        width: auto;
        /* margin-top: 102px; */
        /* border-style: solid;
        border-color: black; */
        height: auto;
        /* border-style: solid; */
        margin-left: 70px;
    
    }
    .mfi-Image1{
        margin-left: 10px;
    }
    .weMake-Big{
        /* font-size: 42px; */
        font-size:48.9px;
        width: 620px;
        height: auto;
        /* border-style: solid;
        border-color: purple; */
        line-height: 52px;
        /* margin-top: 20px; */
    }
    .mfi-Image{
        /* min-width: 600px; */
        margin-left: -0px;
        /* width: 100%; */
        height: 440px;
        margin-top: 100px;
        /* margin-bottom: 10rem; */
        position: relative;
    }
    
    
    #Mobile{
        /* height: 980px; */
        /* border-color: brown;
        border-style: solid; */
        padding-bottom: 9rem;
    }
    #whyCeplr{
        /* height: 660px; */
        /* border-color: #3a86ff;
        border-style: solid; */
        padding-bottom: 12rem;
    }
    .Mobile-image{
        height: 680px;
    }
    #Hero{
        /* min-height: 890px;
        height:100%;
        align-items: center; */
        /* height: 678px; */
        
    }
    
   
 

  }


  
  
  @media screen and (min-width:320px) and (max-width:500px) {
     
    #UseCasesPage{
        /* border-style: solid; */
        justify-content: center;
        min-height: 110px;
        height: auto;
        background: linear-gradient(86.87deg, #4FACFE 5.48%, rgba(0, 242, 254, 0) 190.19%);
        background-image: url('../../images/use\ cases\ banner\ mobile-22.png');
        background-size:100%;
    }
   #UseCasesPage h1{
       margin-left: 0;
        font-size:calc(2vw + 2vh + 2vmin);
   }
   #Section1 {
       /* border-style: solid; */
       justify-content: center;
       padding-bottom: 1.5rem;

   }
   #Section1 .container{
/* border-style: solid; */
   }
   #Section1 .row{
       margin-left: 15px;
       margin-right:15px;
       /* border-style: solid; */
       display: flex;
       flex-flow: column-reverse;
       justify-content: flex-start;

   }
   #Section1 .col{
       justify-content: center;
       align-items: center;
       /* border-style: solid; */
       margin: 0;
       gap: 0;
       height: 100%;
   }

   #Section1 .col:nth-child(1){
       /* border-color: turquoise;
       border-style: solid; */
       margin: 0px;
   }
   .mfiHeading-Ceplr{
       display: none;
       
   }
   .mfiHeading-Ceplr2{
    margin-left: 0;
    margin-top: 20px;
    height: fit-content;
   }

   .mfi-Image{
       width: 100%;
       /* aspect-ratio: 1/2; */
       height: 10%;
       margin-left: 0px;
       /* border-style: solid; */
       margin-top: 0px;
       margin-top: 20px;
   }
   .mfi-Container{
       /* border-style: solid; */
       margin-top: 0px;
   }
   .mfiBigText{
       /* border-style: solid; */
       width: 100%;
   }
   .mfi-smallText1{
       /* border-style: solid; */
       width: 100%;
   }
   .getInTouch-btn{
       width: 100%;
   }

   #Section2 {
       /* border-style: solid; */
       padding-bottom: 2rem;
   }
   #Section2 .row{
       margin-right: 15px;
       margin-left: 15px;
       display: flex;
       flex-direction: column;
   }
   .mfi-Image1{
       width: 100%;
       height: 10%;
       margin-top: 20px;
   }
   #Section2 .col:nth-child(2){
       /* border-style: solid; */
       margin:0;
   }
   .mfi-Container1{
       width: 100%;
       /* border-style: solid; */
       margin-left: 0;
   }
   .mfiBigText1{
       width: 100%;
   }
   .mfi-smallText11{
       width: 100%;
   }
   .getInTouch-btn1{
       width: 100%;
       /* width: fit-content; */
   }
   #Section3{
       /* border-style: solid; */
       padding-bottom: 2rem;
   }

   #Section3 .row{
       margin-left: 15px;
       margin-right: 15px;
       display: flex;
       flex-direction: column-reverse;
   }
   #Section3 .col:nth-child(1){
       margin: 0;
   }
  }