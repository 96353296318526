/* CopyLinkPage.css */
.consent_success_screen{
    height: 100%;
    width:100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 8px;
    position: relative;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: auto; /* Set height to auto to adjust based on content */
    width: 80%;
  }
  
  /* CopyLinkPage.css */

/* CopyLinkPage.css */

.copy-button {
    position: absolute;
    background-color: #f5f5f5;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    text-decoration: none;
    outline: none;
    height: auto;
  }


.regenerate-button {
  margin-top: 10px;
  padding: 8px 16px;
  cursor: pointer;
  color: #0070f3;
  font-size: 18px;
  font-weight: bold;
  border: none;
  text-decoration: none;
  background: none;
  outline: none;
  transition: color 0.3s;
  height: auto;
  width: auto;
}

.regenerate-button:hover {
  color: #0056b3;
}

  
  
  
  
  /* Media query for mobile responsiveness */
  @media (max-width: 768px) {
    .box-container {
      max-width: 100%;
    }
  }
  