#DemoScreen1{
    height: 100%;
    background: transparent;
    /* width: 100%; */
    justify-content: center;
    display: flex;
    align-items: center;
    /* box-sizing: border-box;
    -webkit-box-sizing: border-box; */
}


.hiddenInput{
    display: none;
}
#DemoScreen1::-webkit-scrollbar {
    display: none;
  }
#DemoScreen1 .row{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border-style: solid; */
    /* border-color: yellow; */
    /* padding-right: 20px;
    padding-left: 20px; */
    height: 100%;
    flex:1
}



@media screen and (max-width:8090px) {
    #DemoScreen1{
        /* height: 100vh; */
        width: auto;
        /* border-style: solid; */
        height: 100vh;
        overflow: auto;
        /* padding-bottom: 16rem; */
        
                
    }
    #DemoScreen1 .row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* border-style: solid; */
        /* border-color: yellow; */
        /* padding-right: 20px;
        padding-left: 20px; */
        flex-flow: column;
        padding-bottom: 40rem;
    }
    .imageDivDemo{
        width: fit-content;
        height: fit-content;
        /* border: black 1px solid; */
        /* height: 150px; */
        margin-top: 10px;
    }
    .personalImage{
        height: 100%;
        width: 100%;
    }
    .FormDemoContainer{
        height: max-content;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 5px ;
        padding-right: 5px ;
        /* border: 1px solid red; */
        margin-top: 10px;
        display: flex;
        flex-direction: row;

    }

    .select-option{
        display: flex;
        width: 100%;
        height: 50px;
        margin-top: 25px;
        /* margin-left: 1px; */
        background: #FFFFFF;
border: 1px solid #3F3F3F;
box-sizing: border-box;
border-radius: 3px;
padding-left: 10px;
font-size: 16px;
align-items: center;
justify-content: space-between;
/* padding-left: 20px; */
padding-right: 20px;

    }
    .select-option_top{
        display: flex;
        width: 100%;
        height: 50px;
        /* margin-top: 25px; */
        /* margin-left: 1px; */
        background: #FFFFFF;
border: 1px solid #3F3F3F;
box-sizing: border-box;
border-radius: 3px;
padding-left: 10px;
font-size: 16px;
align-items: center;
justify-content: space-between;
/* padding-left: 20px; */
padding-right: 20px;

/* margin-left: -30px; */
    }
    .dropDownWrapper{
        background-color: transparent;
        width: 100%;
    }
    .dropDownWrapperActive{
        background-color: #ffff;
        width: 100%;
        height: 100vh;
        position: fixed;
        left: 0;
        /* padding: 5px; */
        /* overflow: auto; */
        /* padding-bottom: 20px; */
top: 0;
z-index: 0;
    }
    .dropDownOptions{
        width: auto;
        height: 93%;
        /* border: 1px solid red; */
        scroll-behavior: auto;
        overflow: auto;
        /* padding-bottom: 30px; */
        /* padding-top: 5px; */
        /* padding-bottom: 5px; */
    }
    .options{
        width: auto;
        height: auto;
        border-bottom: 1px solid #3F3F3F;
box-sizing: border-box;
border-radius: 3px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 5px;
        font-style: normal;
        margin-top: 2px;
font-weight: 500;
font-size: 16px;
        
    }
    .searchingInput{
        border: transparent;
        margin-top: 2px;
        margin-bottom: 2px;
        width: 100%;
        outline: none;
    }
    .searchingInput:focus{
        /* border: 22px solid red; */
        outline: none;
    }
    .bankText{
        display: flex;
        align-items: center;
        /* border: 1px solid red; */
        width: 100%;
    }

    .arrow{
        border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 5px;
  width: 5px;
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

    /* .form-label-group label{
        background-color: yellow;
        width: 50%;
    } */

    .dropDown-content{
        display: flex;
        background-color: red;
        width: 10px!important;
    }
    .contact-formDemo{
display: flex;
flex-direction: column;
/* padding-top: 20px; */
padding-bottom: 5%;
padding-left: 5%;
padding-right: 5%;
/* border: 1px solid red; */
width: 100%;
height: max-content;
    }

    .contact-formDemo-active{
        display: flex;
        flex-direction: column;
        /* padding-top: 20px; */
        padding-bottom: 5%;
        /* padding-left: 5%;
        padding-right: 5%; */
        /* border: 1px solid red; */
        width: 100%;
        height: max-content;
            }
    .inputNameLabel{
        display:flex;
        flex-direction: column;
        margin-top: 15px ;
        font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;

color: #3D3D3D;
/* border: 1px solid ; */
height: 80px;
    }
    .inputClassDemo{
        display: flex;
        width: 100%;
        height: 50px;
        margin-top: 2.5%;
        /* margin-left: 1px; */
        background: #FFFFFF;
border: 1px solid #3F3F3F;
box-sizing: border-box;
border-radius: 3px;
padding-left: 10px;
font-size: 16px;


    }
    .inputClassDemo:focus{
        border: 2px solid #3A86FF;
        outline: #3A86FF;

    }
    
    .errorInputClassDemo{
        display: flex;
        width: 100%;
        height: 50px;
        margin-top: 2.5%;
        /* margin-left: 1px; */
        /* background: #FFFFFF; */
border: 2px solid red;
box-sizing: border-box;
border-radius: 3px;
padding-left: 10px;
font-size: 16px;


    }
    .errorInputClassDemo:focus{
        border: 2px solid #3A86FF;
        outline: #3A86FF;

    }
    .buttonDivDemo{
        height: 50px;
        display: flex;
        justify-content: center;
      align-items: center;
      /* border: 1px solid black; */
      margin-top: 10%;
    }

    .proceedButtonDemo{
        background: #3A86FF;
    
        color: #fff;
        border: 1px solid var(--primary);
        /* transition: all .3s ease-out; */
        width: 100%;
        height: 46px;
        font-size: 16px;
        /* border-radius: 2px; */
        cursor: pointer;
        font-family: Asap;
        /* justify-self: stretch; */
        
        position: relative;
        margin-top: 29px;
        /* align-self: flex-star */
        /* margin-bottom: 26rem; */
        border-radius: 4px;
        align-self: center;
        /* display: flex; */
        margin-bottom: 30px;
      }
      .proceedButtonDemo:focus{
          outline: none!important;
          border: black;
      }
    
  .personalError{
    font-family: Asap;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    
    color: red;
    /* border: 1px solid ; */
    height: 0;
    margin-top: 0.2px;
    
  }
}