#FiuSuccess{
    display: flex;
    align-items: center;
    justify-content: center;
   background:  #FFFFFF;
}

.fiuDiv{
    display: flex;
    width: 100%;
    max-width: 690px;
    /* border: 1px solid red; */
    flex-direction: column;
    
}

.greenDiv{
    background: #2EBEAD;
    box-shadow: 0px 5px 10px rgba(120, 120, 120, 0.25);
    border-radius: 0px 0px 33px 33px;
    width: 100%;
    /* height: 35%; */
    height: max-content;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    flex-direction: column;

}
.h1{
    font-family: Asap;
font-style: normal;
font-weight: 600;
font-size: 26px;
line-height: 30px;

color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
}

.smallText{
    font-family: Asap;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #FFFFFF;
/* margin-top: 5px; */
display: flex;
align-items: center;
justify-content: center;
}

.restDiv{
    display: flex;
    flex:1;
    justify-content: center;
    padding: 15px;
    margin-top: 25px;
}

.detailsDiv{
    background: #FFFFFF;
/* border: 1px solid #A4A4A4; */
box-sizing: border-box;
border-radius: 11px;
width: 100%;
/* height: 45%; */
height: fit-content;
display: flex;
align-items: center;
justify-content: center;
padding: 10px;
flex-direction: column;
}

.rupeeText{
    font-family: Asap;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    
    color: #282828;
    margin-top: 10px;
    
}

.rupeeTextError{
    font-family: 'Asap';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;
text-align: center;

color: #7E7E7E;
}
.buttonLoan{
    width: 213px;
height: 42px;
left: 107px;
top: 483px;

background: #3A86FF;
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
font-family: Asap;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 18px;
text-align: center;

color: #FFFFFF;
margin-top: 20px;
border: #3A86FF;
margin-bottom: 10px;
}

